import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import Select from "react-select";

import { ReactBingmaps } from "react-bingmaps";
import { ModalConfirm } from "tamtam-components";
import { v4 as uuidv4 } from "uuid";

import Switch from "../../../common/Switch/Switch";
import { DragHandle } from "../MyPortals";
import styles from "./Portals.module.scss";
import _ from "../../../../i18n";
import Category from "../../Category";
import {
  SELECT_STYLES,
  TTP_MAX_FILE_SIZE,
  TTP_MAX_FILE_SIZE_TEXT,
} from "../../../../config";
import Tabs from "../../../common/Tabs";

import Button from "../../../common/Button";

import MediaList from "../../MediaList";
import CollaboratorsSortableList from "../../CollaboratorsSortableList";
import FormInput from "../../../common/FormInput";

import TimeInput from "../../../common/TimeInput";

import { IconStar } from "../../../common/icons/IconStar";
import { IconTrash } from "../../../common/icons/IconTrash";

import WatchSharing from "../WatchSharing";
import NewsletterModal from "../../../common/NewsletterModal";
import ServicePopOver from "../../ServicePopOver";
import MediaPopOver from "../../MediaPopOver";
import ServicesSortableList from "../../ServicesSortableList";
import { deleteService, saveMedia } from "../../../../api";
import FaqModal from "../../FaqModal";
import FaqsSortableList from "../../FaqsSortableList";
import { deleteFaq } from "../../../../api/faq";
import { deleteTestimonial } from "../../../../api/testimonial";
import TestimonialModal from "../../TestimonialModal";
import TestimonialsSortableList from "../../TestimonialsSortableList";
import { validateEmail, validatePhone } from "../../../../utils";
import Service from "../../ServicesSortableList/Service";
import AddSingleMedia from "../../../common/AddSingleMedia";
import AddMedia from "../../../common/AddMedia";
import IconArrowDown from "../../../common/icons/IconArrowDown";
import BlogSource from "./BlogSource";

const languages = [
  { value: "fr", label: _("french") },
  { value: "en", label: _("english") },
  { value: "nl", label: _("dutch") },
];

const days = ["MON", "TUE", "WED", "TUR", "FRI", "SAT", "SUN"];

export const DefaultApp = ({ value, handleCheckApp, type }) => {
  return (
    <li className={classNames(styles.appsItem, styles.appsItem_header)}>
      <div className={styles.appsItem_top}>
        <DragHandle />
        <img src={`img/apps/${value.icon}`} alt="" />
        {value.name}
      </div>
      <Switch
        isChecked={value.enabled}
        onChange={(e) => handleCheckApp(type, e, value)}
      />
    </li>
  );
};

export const WatchApp = ({ value, handleCheckApp, type }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <li className={classNames(styles.appsItem, styles.appsItem_header)}>
      <div className={styles.appsItem_topwatch}>
        <div className={styles.appsItem_top}>
          <DragHandle />
          <img src={`img/apps/${value.icon}`} alt="" />
          <span className={styles.watch}>
            <span>{value.name}</span>
            <span>
              <span className={styles.watch_tag}>WATCH</span>
            </span>
          </span>
        </div>
        <span className={styles.watch_link} onClick={() => setShowModal(true)}>
          {_("manage_premium_sharing")}
        </span>
      </div>

      <Switch
        isChecked={value.enabled}
        onChange={(e) => handleCheckApp(type, e, value)}
      />
      <WatchSharing showModal={showModal} setShowModal={setShowModal} />
    </li>
  );
};

export const BlogApp = ({
  value,
  handleCheckApp,
  type,
  portals,
  setPortals,
  handleScopeChange,
  scopeFilter,
  setScopeFilter,
  categoryFilter,
  setCategoryFilter,
  initCategoryFilter,
  setInitCategoryFilter,
  authorTypes,
  authorTypesFilter,
  setAuthorTypesFilter,
  sourceFilter,
  setSourceFilter,
}) => {
  const lng = useSelector((state) => state.params.language);
  return (
    <li className={styles.appsItem}>
      <div className={styles.appsItem_header}>
        <div className={styles.appsItem_left}>
          <div className={styles.appsItem_top}>
            <DragHandle />
            <img src={`img/apps/${value.icon}`} alt="" />
            {value.name}
          </div>
          <Switch
            isChecked={value.enabled}
            onChange={(e) => handleCheckApp(type, e, value)}
          />
        </div>
        <div className={styles.appsItem_right}>
          <i
            className={
              value.enabled ? "icon-ttp-chevron-up" : "icon-ttp-chevron-down"
            }
          />
        </div>
      </div>
      {value.enabled && (
        <div className={styles.appsItem_content}>
          {/*<div className={`grid-x ${styles.switchGroup}`}>
            <div
              className={classNames(styles.filter, "cell small-12 medium-4")}
            >
              <label className={styles.configLabel}>{_("article_feed")}</label>
              <ul className={styles.switches}>
                <li>
                  <div
                    className={classNames(
                      styles.checkbox,
                      portals[type].config.apps.filter(
                        (el) => el.value === value.value
                      )[0].type === "BOTH"
                        ? styles.checkbox_active
                        : ""
                    )}
                    onClick={() => {
                      const tab = portals[type].config.apps.map((app) => {
                        if (app.value === value.value) {
                          app.type = "BOTH";
                        }
                        return app;
                      });
                      setPortals({
                        ...portals,
                        [type]: {
                          ...portals[type],
                          config: {
                            ...portals[type].config,
                            apps: tab,
                          },
                        },
                      });
                    }}
                  >
                    {portals[type].config.apps.filter(
                      (el) => el.value === value.value
                    )[0].type === "BOTH" && (
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49976_35660)">
                          <path
                            d="M3.95836 9.44352C3.68037 9.44352 3.41746 9.31425 3.24844 9.09253L0.965733 6.09609C0.668833 5.70637 0.746124 5.15145 1.1382 4.85621C1.53092 4.56085 2.08882 4.63818 2.38584 5.02764L3.88721 6.99818L7.66322 0.971768C7.9232 0.557165 8.47216 0.430308 8.89017 0.68834C9.30767 0.946626 9.43517 1.49266 9.17506 1.90764L4.71441 9.02637C4.55945 9.27386 4.29193 9.42955 3.99899 9.44275C3.98532 9.44326 3.97191 9.44352 3.95836 9.44352Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49976_35660">
                            <rect
                              width="8.94276"
                              height="8.88889"
                              fill="white"
                              transform="translate(0.576172 0.554688)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                  </div>
                  <span>{_("both")}</span>
                </li>
                <li>
                  <div
                    className={classNames(
                      styles.checkbox,
                      portals[type].config.apps.filter(
                        (el) => el.value === value.value
                      )[0].type === "MINE"
                        ? styles.checkbox_active
                        : ""
                    )}
                    onClick={() => {
                      const tab = portals[type].config.apps.map((app) => {
                        if (app.value === value.value) {
                          app.type = "MINE";
                        }
                        return app;
                      });
                      setPortals({
                        ...portals,
                        [type]: {
                          ...portals[type],
                          config: {
                            ...portals[type].config,
                            apps: tab,
                          },
                        },
                      });
                    }}
                  >
                    {portals[type].config.apps.filter(
                      (el) => el.value === value.value
                    )[0].type === "MINE" && (
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49976_35660)">
                          <path
                            d="M3.95836 9.44352C3.68037 9.44352 3.41746 9.31425 3.24844 9.09253L0.965733 6.09609C0.668833 5.70637 0.746124 5.15145 1.1382 4.85621C1.53092 4.56085 2.08882 4.63818 2.38584 5.02764L3.88721 6.99818L7.66322 0.971768C7.9232 0.557165 8.47216 0.430308 8.89017 0.68834C9.30767 0.946626 9.43517 1.49266 9.17506 1.90764L4.71441 9.02637C4.55945 9.27386 4.29193 9.42955 3.99899 9.44275C3.98532 9.44326 3.97191 9.44352 3.95836 9.44352Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49976_35660">
                            <rect
                              width="8.94276"
                              height="8.88889"
                              fill="white"
                              transform="translate(0.576172 0.554688)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                  </div>
                  <span>{_("my_articles")}</span>
                </li>
                <li>
                  <div
                    className={classNames(
                      styles.checkbox,
                      portals[type].config.apps.filter(
                        (el) => el.value === value.value
                      )[0].type === "FFF"
                        ? styles.checkbox_active
                        : ""
                    )}
                    onClick={() => {
                      const tab = portals[type].config.apps.map((app) => {
                        if (app.value === value.value) {
                          app.type = "FFF";
                        }
                        return app;
                      });
                      setPortals({
                        ...portals,
                        [type]: {
                          ...portals[type],
                          config: {
                            ...portals[type].config,
                            apps: tab,
                          },
                        },
                      });
                    }}
                  >
                    {portals[type].config.apps.filter(
                      (el) => el.value === value.value
                    )[0].type === "FFF" && (
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49976_35660)">
                          <path
                            d="M3.95836 9.44352C3.68037 9.44352 3.41746 9.31425 3.24844 9.09253L0.965733 6.09609C0.668833 5.70637 0.746124 5.15145 1.1382 4.85621C1.53092 4.56085 2.08882 4.63818 2.38584 5.02764L3.88721 6.99818L7.66322 0.971768C7.9232 0.557165 8.47216 0.430308 8.89017 0.68834C9.30767 0.946626 9.43517 1.49266 9.17506 1.90764L4.71441 9.02637C4.55945 9.27386 4.29193 9.42955 3.99899 9.44275C3.98532 9.44326 3.97191 9.44352 3.95836 9.44352Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49976_35660">
                            <rect
                              width="8.94276"
                              height="8.88889"
                              fill="white"
                              transform="translate(0.576172 0.554688)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                  </div>
                  <span>{_("fff_articles")}</span>
                </li>
              </ul>
            </div>
            <div
              className={classNames(styles.filter, "cell small-12 medium-4")}
            >
              <label className={styles.configLabel}>{_("Scope")}</label>
              <ul className={styles.switches}>
                <li>
                  <div
                    className={classNames(
                      styles.checkbox,
                      scopeFilter.includes("PUBLIC")
                        ? styles.checkbox_active
                        : ""
                    )}
                    onClick={() => {
                      handleScopeChange(
                        "PUBLIC",
                        !scopeFilter.includes("PUBLIC"),
                        scopeFilter,
                        setScopeFilter
                      );
                    }}
                  >
                    {scopeFilter.includes("PUBLIC") && (
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49976_35660)">
                          <path
                            d="M3.95836 9.44352C3.68037 9.44352 3.41746 9.31425 3.24844 9.09253L0.965733 6.09609C0.668833 5.70637 0.746124 5.15145 1.1382 4.85621C1.53092 4.56085 2.08882 4.63818 2.38584 5.02764L3.88721 6.99818L7.66322 0.971768C7.9232 0.557165 8.47216 0.430308 8.89017 0.68834C9.30767 0.946626 9.43517 1.49266 9.17506 1.90764L4.71441 9.02637C4.55945 9.27386 4.29193 9.42955 3.99899 9.44275C3.98532 9.44326 3.97191 9.44352 3.95836 9.44352Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49976_35660">
                            <rect
                              width="8.94276"
                              height="8.88889"
                              fill="white"
                              transform="translate(0.576172 0.554688)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                  </div>
                  <span>{_("all")}</span>
                </li>
                <li>
                  <div
                    className={classNames(
                      styles.checkbox,
                      scopeFilter.includes("CLIENT")
                        ? styles.checkbox_active
                        : ""
                    )}
                    onClick={() => {
                      handleScopeChange(
                        "CLIENT",
                        !scopeFilter.includes("CLIENT"),
                        scopeFilter,
                        setScopeFilter
                      );
                    }}
                  >
                    {scopeFilter.includes("CLIENT") && (
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49976_35660)">
                          <path
                            d="M3.95836 9.44352C3.68037 9.44352 3.41746 9.31425 3.24844 9.09253L0.965733 6.09609C0.668833 5.70637 0.746124 5.15145 1.1382 4.85621C1.53092 4.56085 2.08882 4.63818 2.38584 5.02764L3.88721 6.99818L7.66322 0.971768C7.9232 0.557165 8.47216 0.430308 8.89017 0.68834C9.30767 0.946626 9.43517 1.49266 9.17506 1.90764L4.71441 9.02637C4.55945 9.27386 4.29193 9.42955 3.99899 9.44275C3.98532 9.44326 3.97191 9.44352 3.95836 9.44352Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49976_35660">
                            <rect
                              width="8.94276"
                              height="8.88889"
                              fill="white"
                              transform="translate(0.576172 0.554688)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                  </div>
                  <span>{_("Clients")}</span>
                </li>
                <li>
                  <div
                    className={classNames(
                      styles.checkbox,
                      scopeFilter.includes("COLLABORATOR")
                        ? styles.checkbox_active
                        : ""
                    )}
                    onClick={() => {
                      handleScopeChange(
                        "COLLABORATOR",
                        !scopeFilter.includes("COLLABORATOR"),
                        scopeFilter,
                        setScopeFilter
                      );
                    }}
                  >
                    {scopeFilter.includes("COLLABORATOR") && (
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_49976_35660)">
                          <path
                            d="M3.95836 9.44352C3.68037 9.44352 3.41746 9.31425 3.24844 9.09253L0.965733 6.09609C0.668833 5.70637 0.746124 5.15145 1.1382 4.85621C1.53092 4.56085 2.08882 4.63818 2.38584 5.02764L3.88721 6.99818L7.66322 0.971768C7.9232 0.557165 8.47216 0.430308 8.89017 0.68834C9.30767 0.946626 9.43517 1.49266 9.17506 1.90764L4.71441 9.02637C4.55945 9.27386 4.29193 9.42955 3.99899 9.44275C3.98532 9.44326 3.97191 9.44352 3.95836 9.44352Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_49976_35660">
                            <rect
                              width="8.94276"
                              height="8.88889"
                              fill="white"
                              transform="translate(0.576172 0.554688)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                  </div>
                  <span>{_("Collaborators")}</span>
                </li>
              </ul>
            </div>
            <div
              className={classNames(styles.filter, "cell small-12 medium-4")}
              style={{ border: "none" }}
            >
              <label className={styles.configLabel}>{_("relevance")}</label>
              <div>
                <Rating
                  onClick={(e) => {
                    const tab = portals[type].config.apps.map((app) => {
                      if (app.value === value.value) {
                        app.relevance = e / 20;
                      }
                      return app;
                    });
                    setPortals({
                      ...portals,
                      [type]: {
                        ...portals[type],
                        config: {
                          ...portals[type].config,
                          apps: tab,
                        },
                      },
                    });
                  }}
                  ratingValue={
                    portals[type].config.apps.filter(
                      (el) => el.value === value.value
                    )[0].relevance * 20
                  }
                  size={22}
                  fillColor="#18a0fb"
                />
              </div>
            </div>
                </div>*/}

          <div className={`grid-x grid-margin-x`}>
            <div className="cell small-12 medium-6">
              <label className={styles.config_Label}>{_("category")}</label>
              <Category
                language={lng}
                category={categoryFilter}
                setCategory={(e) => {
                  setCategoryFilter(e);
                  const tab = portals[type].config.apps.map((app) => {
                    if (app.value === "BLOG") {
                      app.categories = e;
                    }
                    return app;
                  });
                  setPortals({
                    ...portals,
                    [type]: {
                      ...portals[type],
                      config: {
                        ...portals[type].config,
                        apps: tab,
                      },
                    },
                  });
                }}
                initialCategories={initCategoryFilter}
                setInitCategoryFilter={setInitCategoryFilter}
              />
            </div>
            <div className="cell small-12 medium-6">
              <label className={styles.config_Label}>{_("author_type")}</label>
              <Select
                styles={SELECT_STYLES}
                options={authorTypes}
                placeholder={_("select_type")}
                value={authorTypesFilter}
                onChange={(e) => {
                  setAuthorTypesFilter(e);
                  const tab = portals[type].config.apps.map((app) => {
                    if (app.value === "BLOG") {
                      app.authorTypes = e;
                    }
                    return app;
                  });
                  setPortals({
                    ...portals,
                    [type]: {
                      ...portals[type],
                      config: {
                        ...portals[type].config,
                        apps: tab,
                      },
                    },
                  });
                }}
                isMulti
                noOptionsMessage={() => _(`no_options`)}
              />
            </div>
          </div>

          <BlogSource
            disabledItems={type === "client" ? ["COLLEAGUE"] : []}
            source={sourceFilter}
            setSource={(e) => {
              setSourceFilter(e);
              const tab = portals[type].config.apps.map((app) => {
                if (app.value === "BLOG") {
                  app.source = e;
                }
                return app;
              });
              setPortals({
                ...portals,
                [type]: {
                  ...portals[type],
                  config: {
                    ...portals[type].config,
                    apps: tab,
                  },
                },
              });
            }}
          />
        </div>
      )}
    </li>
  );
};

export const BlogAppPublic = ({
  handlePortalBlocChange,
  portals,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  inModal,
}) => {
  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <img src={`img/apps/blog.svg`} alt="" />
            <label>{_("News")}</label>
          </div>
          <Switch
            isChecked={portals.public.config.news.activated}
            onChange={(e) => handlePortalBlocChange(e, "news")}
          />
        </div>
      )}
      {portals.public.config.news.activated && (
        <>
          <Tabs theme="wtheme">
            {languages.map((language) => (
              <div
                key={`langtab${language.value}`}
                label={language.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
              >
                <div className={styles.langTab}>
                  <label className={styles.configLabel}>
                    {_(`title_${language.value}`)}
                  </label>

                  <input
                    className={styles.formInput}
                    value={
                      portals.public.config.news[titleAttr(language.value)]
                    }
                    onChange={(e) =>
                      handlePortalBlocTitleChange(
                        language.value,
                        e.target.value,
                        "news"
                      )
                    }
                  />

                  <label
                    className={`${styles.configLabel} ${styles.descriptionLabel}`}
                  >
                    {_(`description_${language.value}`)}
                  </label>
                  <textarea
                    rows="4"
                    className={styles.descriptionInput}
                    value={
                      portals.public.config.news[
                        descriptionAttr(language.value)
                      ]
                    }
                    onChange={(e) =>
                      handlePortalBlocDescriptionChange(
                        language.value,
                        e.target.value,
                        "news"
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </Tabs>
        </>
      )}
    </div>
  );
};

export const MediaAppPublic = ({
  handlePortalBlocChange,
  portals,
  setAddMediaModalOpened,
  medias,
  setMedias,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
}) => {
  const lng = useSelector((state) => state.params.language);
  return (
    <div className={styles.portalBloc}>
      <div className={styles.portalBloc_top}>
        <div className={styles.portalBloc_top_left}>
          <img src={`img/apps/media.svg`} alt="" />
          <label>{_("carousel")}</label>
        </div>
        <Switch
          isChecked={portals.public.config.media.activated}
          onChange={(e) => handlePortalBlocChange(e, "media")}
        />
      </div>
      {portals.public.config.media.activated && (
        <>
          <Tabs theme="wtheme">
            {languages.map((language) => (
              <div
                key={`langtab${language.value}`}
                label={language.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
              >
                <div className={styles.langTab}>
                  <label
                    className={`${styles.configLabel} ${styles.descriptionLabel}`}
                  >
                    {_(`description_${language.value}`)}
                  </label>
                  <textarea
                    rows="4"
                    className={styles.descriptionInput}
                    value={
                      portals.public.config.media[
                        descriptionAttr(language.value)
                      ]
                    }
                    onChange={(e) =>
                      handlePortalBlocDescriptionChange(
                        language.value,
                        e.target.value,
                        "media"
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </Tabs>
          <p className={styles.mediaTitle}>
            Choississez les images que vous désirez voir apparaitre dans votre
            bannière.
          </p>
          <Button
            className={styles.addMedia}
            size={"sm"}
            onClick={() => setAddMediaModalOpened(true)}
          >
            {_("add_media")}
          </Button>
          {medias && (
            <MediaList
              medias={medias}
              setMedias={setMedias}
              language={lng}
              isUsed={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export const MemberMediaAppPublic = ({
  handlePortalBlocChange,
  portals,
  medias,
  setMedias,
  maxPicks = 3,
  inModal,
}) => {
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(false);

  const allowAdd = () => {
    if (maxPicks === -1) {
      return true;
    }
    return !(maxPicks < medias.length + 1);
  };

  const afterMediaSave = (val) => {
    if (medias.map((el) => el.id).includes(val.id)) {
      setMedias(
        medias.map((el) => {
          if (el.id === val.id) {
            return val;
          }
          return el;
        })
      );
    } else {
      setMedias([val, ...medias]);
    }
    setOpenMediaModal(false);
  };

  const onDelete = (val) => {
    setMedias(medias.filter((el) => el.id !== val.id));
  };

  const onEdit = (val) => {
    setCurrentMedia(medias.filter((el) => el.id === val.id)[0]);
    setOpenMediaModal(true);
  };

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <img src={`img/apps/media.svg`} alt="" />
            <label>{_("carousel")}</label>
          </div>
          <Switch
            isChecked={portals.public.config.memberMedia.activated}
            onChange={(e) => handlePortalBlocChange(e, "memberMedia")}
          />
        </div>
      )}
      {portals.public.config.memberMedia.activated && (
        <>
          <p className={styles.mediaTitle}>
            Choississez les images que vous désirez voir apparaitre dans votre
            bannière.
          </p>
          {allowAdd() && (
            <Button
              className={styles.addMedia}
              size={"sm"}
              onClick={() => setOpenMediaModal(true)}
            >
              {_("add_media")}
            </Button>
          )}

          {medias.length > 0 && (
            <div className={classNames(styles.mediasList, "grid-x")}>
              {medias.map((media) => (
                <div
                  key={media.id}
                  className={classNames(
                    styles.mediaCont,
                    "cell small-12 medium-6 large-4"
                  )}
                >
                  <Service
                    service={{
                      ...media,
                      image: media.image.fullMediaUrl,
                    }}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onHide={null}
                  />
                </div>
              ))}
            </div>
          )}

          <MediaPopOver
            languages={languages}
            isOpen={openMediaModal}
            onClose={() => setOpenMediaModal(false)}
            afterMediaSave={afterMediaSave}
            currentElement={currentMedia}
            setCurrentElement={setCurrentMedia}
          />
        </>
      )}
    </div>
  );
};

export const AboutAppPublic = ({
  editSection,
  handlePortalBlocChange,
  portals,
  aboutImage,
  setAboutImage,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  handleAboutPageBlocChange,
  handleAboutPageBlocTitleChange,
  handleAboutPageBlocDescriptionChange,
  valuesList,
  setValuesList,
  valuesVideo,
  setValuesVideo,
  galleryMedias,
  setGalleryMedias,
  showPageConfig = false,
  inModal,
}) => {
  const fileInput = useRef();
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [addMediaModalOpened, setAddMediaModalOpened] = useState(false);
  const [galleryModalOpened, setGalleryModalOpened] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [tmp, setTmp] = useState("");

  const uploadFile = async (file) => {
    setIsUploading(true);
    try {
      const res = await saveMedia({
        token: auth.token,
        community: fiduciary,
        file,
      });
      setTmp("");
      setValuesVideo(res.data.data.fullMediaUrl);
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);

      console.error(error);
    }
  };

  // IconUploadSpinner
  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <label>{_("about")}</label>
          </div>
          <Switch
            isChecked={portals.public.config.about.activated}
            onChange={(e) => handlePortalBlocChange(e, "about")}
          />
        </div>
      )}
      {/* {portals.public.config.about.activated && ( */}
      <>
        {!showPageConfig ? (
          <>
            <Tabs theme="wtheme">
              {languages.map((language) => (
                <div
                  key={`langtab${language.value}`}
                  label={language.label}
                  icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                >
                  <div className={styles.langTab}>
                    <label className={styles.configLabel}>
                      {_(`title_${language.value}`)}
                    </label>

                    <input
                      className={styles.formInput}
                      value={
                        portals.public.config.about[titleAttr(language.value)]
                      }
                      onChange={(e) =>
                        handlePortalBlocTitleChange(
                          language.value,
                          e.target.value,
                          "about"
                        )
                      }
                    />

                    <label
                      className={`${styles.configLabel} ${styles.descriptionLabel}`}
                    >
                      {_(`description_${language.value}`)}
                    </label>
                    <textarea
                      rows="4"
                      className={styles.descriptionInput}
                      value={
                        portals.public.config.about[
                          descriptionAttr(language.value)
                        ]
                      }
                      onChange={(e) =>
                        handlePortalBlocDescriptionChange(
                          language.value,
                          e.target.value,
                          "about"
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </Tabs>
            <div
              className={styles.dropZone}
              style={{
                backgroundImage: `url(${
                  aboutImage ? aboutImage.fullMediaUrl : ""
                })`,
              }}
              onClick={() => setAddMediaModalOpened(true)}
            >
              {!aboutImage && (
                <>
                  <div className={styles.text}>
                    <span>{_("image_import_text")}</span>
                  </div>
                </>
              )}
            </div>
            <AddSingleMedia
              isOpen={addMediaModalOpened}
              close={() => {
                setAddMediaModalOpened(false);
              }}
              token={auth.token}
              community={fiduciary}
              setImage={setAboutImage}
            />
          </>
        ) : (
          <div>
            {editSection}
            {editSection === "aboutBanner1" && (
              <div className={styles.section}>
                <div className={styles.top}>
                  <div className={styles.name}>{_("banner")}</div>
                </div>
                <Tabs theme="wtheme">
                  {languages.map((language) => (
                    <div
                      key={`langtab${language.value}`}
                      label={language.label}
                      icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                    >
                      <div className={styles.langTab}>
                        <label className={styles.configLabel}>
                          {_(`title_${language.value}`)}
                        </label>

                        <input
                          className={styles.formInput}
                          value={
                            portals.public.config?.aboutPage?.banner[
                              titleAttr(language.value)
                            ]
                          }
                          onChange={(e) =>
                            handleAboutPageBlocTitleChange(
                              language.value,
                              e.target.value,
                              "aboutPage|banner"
                            )
                          }
                        />

                        <label
                          className={`${styles.configLabel} ${styles.descriptionLabel}`}
                        >
                          {_(`description_${language.value}`)}
                        </label>
                        <textarea
                          rows="4"
                          className={styles.descriptionInput}
                          value={
                            portals.public.config?.about?.aboutPage?.banner[
                              descriptionAttr(language.value)
                            ]
                          }
                          onChange={(e) =>
                            handleAboutPageBlocDescriptionChange(
                              language.value,
                              e.target.value,
                              "aboutPage|banner"
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
                </Tabs>
              </div>
            )}
            {editSection === "history" && (
              <div className={styles.section}>
                <div className={styles.top}>
                  <div className={styles.name}>{_("history")}</div>
                </div>

                {portals.public.config.about.aboutPage.history.activated && (
                  <Tabs theme="wtheme">
                    {languages.map((language) => (
                      <div
                        key={`langtab${language.value}`}
                        label={language.label}
                        icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                      >
                        <div className={styles.langTab}>
                          <label className={styles.configLabel}>
                            {_(`title_${language.value}`)}
                          </label>

                          <input
                            className={styles.formInput}
                            value={
                              portals.public.config.about.aboutPage.history[
                                titleAttr(language.value)
                              ]
                            }
                            onChange={(e) =>
                              handleAboutPageBlocTitleChange(
                                language.value,
                                e.target.value,
                                "history"
                              )
                            }
                          />

                          <label
                            className={`${styles.configLabel} ${styles.descriptionLabel}`}
                          >
                            {_(`description_${language.value}`)}
                          </label>
                          <textarea
                            rows="4"
                            className={styles.descriptionInput}
                            value={
                              portals.public.config.about.aboutPage.history[
                                descriptionAttr(language.value)
                              ]
                            }
                            onChange={(e) =>
                              handleAboutPageBlocDescriptionChange(
                                language.value,
                                e.target.value,
                                "history"
                              )
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </Tabs>
                )}
              </div>
            )}
            {editSection === "values" && (
              <>
                <div className={styles.section}>
                  <div className={styles.top}>
                    <div className={styles.name}>{_("values")}</div>
                    <Switch
                      isChecked={
                        portals.public.config.about.aboutPage.values.activated
                      }
                      onChange={(e) => handleAboutPageBlocChange(e, "values")}
                    />
                  </div>

                  {/* {portals.public.config.about.aboutPage.values.activated && ( */}
                  <>
                    <Tabs theme="wtheme">
                      {languages.map((language) => (
                        <div
                          key={`langtab${language.value}`}
                          label={language.label}
                          icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                        >
                          <div className={styles.langTab}>
                            <label className={styles.configLabel}>
                              {_(`title_${language.value}`)}
                            </label>

                            <input
                              className={styles.formInput}
                              value={
                                portals.public.config.about.aboutPage.values[
                                  titleAttr(language.value)
                                ]
                              }
                              onChange={(e) =>
                                handleAboutPageBlocTitleChange(
                                  language.value,
                                  e.target.value,
                                  "values"
                                )
                              }
                            />

                            <label
                              className={`${styles.configLabel} ${styles.descriptionLabel}`}
                            >
                              {_(`description_${language.value}`)}
                            </label>
                            <textarea
                              rows="4"
                              className={styles.descriptionInput}
                              value={
                                portals.public.config.about.aboutPage.values[
                                  descriptionAttr(language.value)
                                ]
                              }
                              onChange={(e) =>
                                handleAboutPageBlocDescriptionChange(
                                  language.value,
                                  e.target.value,
                                  "values"
                                )
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </Tabs>
                    {valuesList.length < 4 && (
                      <Button
                        className={styles.addMedia}
                        size={"sm"}
                        onClick={() =>
                          setValuesList([
                            ...valuesList,
                            {
                              id: uuidv4(),
                              title: "",
                              description: "",
                            },
                          ])
                        }
                      >
                        {_("add_new_value")}
                      </Button>
                    )}

                    {valuesList.length > 0 &&
                      valuesList.map((item) => (
                        <div key={item.id} className={styles.numberForm}>
                          <input
                            className={classNames(
                              styles.formInput,
                              styles.title
                            )}
                            placeholder={_("title")}
                            value={item.title}
                            onChange={(e) => {
                              setValuesList(
                                valuesList.map((el) => {
                                  if (el.id === item.id) {
                                    return {
                                      ...el,
                                      title: e.target.value,
                                    };
                                  }
                                  return el;
                                })
                              );
                            }}
                          />
                          <input
                            className={classNames(
                              styles.formInput,
                              styles.description
                            )}
                            placeholder={_("description")}
                            value={item.description}
                            onChange={(e) => {
                              setValuesList(
                                valuesList.map((el) => {
                                  if (el.id === item.id) {
                                    return {
                                      ...el,
                                      description: e.target.value,
                                    };
                                  }
                                  return el;
                                })
                              );
                            }}
                          />
                          <button
                            onClick={() => {
                              setValuesList(
                                valuesList.filter((el) => el.id !== item.id)
                              );
                            }}
                          >
                            <IconTrash />
                          </button>
                        </div>
                      ))}
                    <div className={styles.video}>
                      <input
                        type="file"
                        accept="video/*"
                        ref={fileInput}
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setTmp(e.target.value);
                          uploadFile(e.target.files[0]);
                        }}
                        value={tmp}
                      />
                      <div className={styles.flex}>
                        <Button
                          className={styles.addMedia}
                          size={"sm"}
                          onClick={() => fileInput?.current?.click()}
                          disabled={isUploading}
                        >
                          {_(isUploading ? "uploading" : "add_video")}
                        </Button>
                        {valuesVideo && (
                          <Button
                            className={styles.addMedia}
                            size={"sm"}
                            variant={"danger"}
                            onClick={() => setValuesVideo(null)}
                          >
                            {_("remove_video")}
                          </Button>
                        )}
                      </div>

                      {valuesVideo && (
                        <video
                          className={styles.video_preview}
                          src={valuesVideo}
                          controls
                        ></video>
                      )}
                    </div>
                  </>
                  {/* )} */}
                </div>
              </>
            )}
            {editSection === "about" && (
              <div className={styles.section}>
                <div className={styles.top}>
                  <div className={styles.name}>{_("gallery")}</div>
                  <Switch
                    isChecked={
                      portals.public.config.about.aboutPage.gallery.activated
                    }
                    onChange={(e) => handleAboutPageBlocChange(e, "gallery")}
                  />
                </div>
                {portals.public.config.about.aboutPage.gallery.activated && (
                  <>
                    <Tabs theme="wtheme">
                      {languages.map((language) => (
                        <div
                          key={`langtab${language.value}`}
                          label={language.label}
                          icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                        >
                          <div className={styles.langTab}>
                            <label className={styles.configLabel}>
                              {_(`title_${language.value}`)}
                            </label>

                            <input
                              className={styles.formInput}
                              value={
                                portals.public.config.about.aboutPage.gallery[
                                  titleAttr(language.value)
                                ]
                              }
                              onChange={(e) =>
                                handleAboutPageBlocTitleChange(
                                  language.value,
                                  e.target.value,
                                  "gallery"
                                )
                              }
                            />

                            <label
                              className={`${styles.configLabel} ${styles.descriptionLabel}`}
                            >
                              {_(`description_${language.value}`)}
                            </label>
                            <textarea
                              rows="4"
                              className={styles.descriptionInput}
                              value={
                                portals.public.config.about.aboutPage.gallery[
                                  descriptionAttr(language.value)
                                ]
                              }
                              onChange={(e) =>
                                handleAboutPageBlocDescriptionChange(
                                  language.value,
                                  e.target.value,
                                  "gallery"
                                )
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </Tabs>
                    {galleryMedias.length < 7 && (
                      <Button
                        className={styles.addMedia}
                        size={"sm"}
                        onClick={() => setGalleryModalOpened(true)}
                      >
                        {_("add_media")}
                      </Button>
                    )}
                    <MediaList
                      medias={galleryMedias}
                      setMedias={setGalleryMedias}
                      language={lng}
                      isUsed={true}
                    />
                    <AddMedia
                      isOpen={galleryModalOpened}
                      close={() => {
                        setGalleryModalOpened(false);
                      }}
                      token={auth.token}
                      community={fiduciary}
                      medias={galleryMedias}
                      setMedias={(val) =>
                        setGalleryMedias([
                          ...galleryMedias,
                          ...val.slice(0, 7 - galleryMedias.length),
                        ])
                      }
                    />
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </>
      {/* )} */}
    </div>
  );
};
export const SingleServiceAppPublic = ({
  handlePortalBlocChange,
  portals,
  singleServiceImage,
  setSingleServiceImage,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  inModal,
}) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [addMediaModalOpened, setAddMediaModalOpened] = useState(false);

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <label>{_("Services")}</label>
          </div>
          <Switch
            isChecked={portals.public.config.singleService.activated}
            onChange={(e) => handlePortalBlocChange(e, "singleService")}
          />
        </div>
      )}
      {portals.public.config.singleService.activated && (
        <>
          <Tabs theme="wtheme">
            {languages.map((language) => (
              <div
                key={`langtab${language.value}`}
                label={language.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
              >
                <div className={styles.langTab}>
                  <label className={styles.configLabel}>
                    {_(`title_${language.value}`)}
                  </label>

                  <input
                    className={styles.formInput}
                    value={
                      portals.public.config.singleService[
                        titleAttr(language.value)
                      ]
                    }
                    onChange={(e) =>
                      handlePortalBlocTitleChange(
                        language.value,
                        e.target.value,
                        "singleService"
                      )
                    }
                  />

                  <label
                    className={`${styles.configLabel} ${styles.descriptionLabel}`}
                  >
                    {_(`description_${language.value}`)}
                  </label>
                  <textarea
                    rows="4"
                    className={styles.descriptionInput}
                    value={
                      portals.public.config.singleService[
                        descriptionAttr(language.value)
                      ]
                    }
                    onChange={(e) =>
                      handlePortalBlocDescriptionChange(
                        language.value,
                        e.target.value,
                        "singleService"
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </Tabs>
          <div
            className={styles.dropZone}
            style={{
              backgroundImage: `url(${
                singleServiceImage ? singleServiceImage.fullMediaUrl : ""
              })`,
            }}
            onClick={() => setAddMediaModalOpened(true)}
          >
            {!singleServiceImage && (
              <>
                <div className={styles.text}>
                  <span>{_("image_import_text")}</span>
                </div>
              </>
            )}
          </div>
          <AddSingleMedia
            isOpen={addMediaModalOpened}
            close={() => {
              setAddMediaModalOpened(false);
            }}
            token={auth.token}
            community={fiduciary}
            setImage={setSingleServiceImage}
          />
        </>
      )}
    </div>
  );
};

export const NewsletterApp = ({ value, handleCheckApp, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li className={classNames(styles.appsItem, styles.appsItem_header)}>
      <div className={styles.appsItem_topwatch}>
        <div className={styles.appsItem_top}>
          <DragHandle />
          <img src={`img/apps/${value.icon}`} alt="" />
          <span className={styles.watch}>
            <span>{value.name}</span>
          </span>
        </div>
        <span className={styles.watch_link} onClick={() => setIsOpen(true)}>
          {_("manage_newsletter_campaign")}
        </span>
      </div>

      <Switch
        isChecked={value.enabled}
        onChange={(e) => handleCheckApp(type, e, value)}
      />
      <NewsletterModal
        isOpen={isOpen}
        title={_("newsletter_preferences_title")}
        inProcess={false}
        setIsOpen={setIsOpen}
      />
    </li>
  );
};

const Checkbox = ({ checked, radio, onClick }) => {
  return (
    <div
      className={classNames(
        styles.checkbox,
        radio ? styles.radio : "",
        checked ? styles.checkbox_active : ""
      )}
      onClick={onClick}
    >
      {checked && (
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_49976_35660)">
            <path
              d="M3.95836 9.44352C3.68037 9.44352 3.41746 9.31425 3.24844 9.09253L0.965733 6.09609C0.668833 5.70637 0.746124 5.15145 1.1382 4.85621C1.53092 4.56085 2.08882 4.63818 2.38584 5.02764L3.88721 6.99818L7.66322 0.971768C7.9232 0.557165 8.47216 0.430308 8.89017 0.68834C9.30767 0.946626 9.43517 1.49266 9.17506 1.90764L4.71441 9.02637C4.55945 9.27386 4.29193 9.42955 3.99899 9.44275C3.98532 9.44326 3.97191 9.44352 3.95836 9.44352Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_49976_35660">
              <rect
                width="8.94276"
                height="8.88889"
                fill="white"
                transform="translate(0.576172 0.554688)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export const SpecialtyAppPublic = ({
  handlePortalBlocChange,
  portals,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  specialties,
  folderSpecialties,
  setFolderSpecialties,
  maxFavorites,
  maxPicks,
  allowFavorites,
  inModal,
}) => {
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [currentType, setCurrentType] = useState(null);

  useEffect(() => {
    if (!currentType && specialties)
      setCurrentType(specialties.filter((el) => el.isActivated)[0]);
  }, [specialties]);

  const allowAdd = ({ list }) => {
    const typeSpecialties = currentType.specialties
      .map((spec) => {
        return [
          spec.id,
          ...(spec.specialties ? spec.specialties.map((el) => el.id) : []),
        ];
      })
      .flat(1);

    const usedTypeSpecialties = list.filter((el) =>
      typeSpecialties.includes(el)
    );

    if (maxPicks !== -1 && maxPicks < usedTypeSpecialties.length + 1) {
      return false;
    }
    return true;
  };

  const handleCheckChange = ({ specialty, subSpecialty }) => {
    let list = folderSpecialties?.list;
    let favorites = folderSpecialties?.favorites;
    if (subSpecialty) {
      if (!specialty.isMultiSelect) {
        if (!list.includes(specialty.id)) {
          return null;
        }
        if (allowAdd({ list })) {
          list = list.filter(
            (el) => !specialty.specialties.map((em) => em.id).includes(el)
          );
          list.push(subSpecialty.id);
        }
      } else {
        if (list.includes(subSpecialty.id)) {
          list = list.filter((el) => el !== subSpecialty.id);
          if (favorites.includes(subSpecialty.id)) {
            favorites = favorites.filter((el) => el !== subSpecialty.id);
          }
        } else {
          if (allowAdd({ list })) {
            list.push(subSpecialty.id);
          }
        }
      }
    } else {
      if (allowAdd({ list })) {
        list = list.filter(
          (el) =>
            !currentType.specialties
              .map((spec) => {
                return [
                  spec.id,
                  ...(spec.specialties
                    ? spec.specialties.map((el) => el.id)
                    : []),
                ];
              })
              .flat(1)
              .includes(el)
        );
        list.push(specialty.id);
      }
    }

    setFolderSpecialties({ favorites, list });
  };

  const allowAddFavorite = ({ favorites }) => {
    const typeSpecialties = currentType.specialties
      .map((spec) => {
        return [
          spec.id,
          ...(spec.specialties ? spec.specialties.map((el) => el.id) : []),
        ];
      })
      .flat(1);

    const usedTypeSpecialties = favorites.filter((el) =>
      typeSpecialties.includes(el)
    );

    if (maxFavorites !== -1 && maxFavorites < usedTypeSpecialties.length + 1) {
      return false;
    }
    return true;
  };

  const checkFavorite = (id, specialty) => {
    let favorites = folderSpecialties.favorites;
    let list = folderSpecialties.list;

    if (favorites.includes(id)) {
      favorites = favorites.filter((el) => el !== id);
    } else if (allowAddFavorite({ favorites })) {
      favorites.push(id);
      if (!list.includes(id)) {
        list.push(id);
      }
    }
    setFolderSpecialties({ list, favorites });
  };

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <img src={`img/apps/specialty.svg`} alt="" />
            <label>{_("Specialties")}</label>
          </div>
          {/* <Switch
            name="specialty"
            isChecked={portals.public.config.specialty.activated}
            onChange={(e) => handlePortalBlocChange(e, "specialty")}
          /> */}
        </div>
      )}
      {/* {portals.public.config.specialty.activated && ( */}
      <>
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={
                    portals.public.config.specialty[titleAttr(language.value)]
                  }
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      "specialty"
                    )
                  }
                />

                <label
                  className={`${styles.configLabel} ${styles.descriptionLabel}`}
                >
                  {_(`description_${language.value}`)}
                </label>
                <textarea
                  rows="4"
                  className={styles.descriptionInput}
                  value={
                    portals.public.config.specialty[
                      descriptionAttr(language.value)
                    ]
                  }
                  onChange={(e) =>
                    handlePortalBlocDescriptionChange(
                      language.value,
                      e.target.value,
                      "specialty"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
        {specialties && (
          <>
            <hr className={styles.separator} />
            <div className={styles.specialtyContent}>
              <h2 className={styles.title}>{_("Specialties")}</h2>
              <span className={styles.subtitle}>
                {_("specialties_subtitle")}
              </span>
              <ul className={styles.typesList}>
                {specialties.map((type) => {
                  if (!type.isActivated) return null;
                  return (
                    <li
                      className={
                        currentType && currentType.id === type.id
                          ? styles.active
                          : ""
                      }
                      onClick={() => setCurrentType(type)}
                    >
                      {type[nameAttr]}
                    </li>
                  );
                })}
              </ul>
              {currentType && currentType.specialties && (
                <div className="grid-x">
                  {currentType.specialties.map((specialty, i) => (
                    <div
                      className={classNames(
                        "cell small-4",
                        styles.specialty,
                        (i + 1) % 3 === 0 ? styles.noBorder : ""
                      )}
                    >
                      <div className={styles.title}>
                        {currentType.hasChoices && (
                          <Checkbox
                            checked={folderSpecialties?.list.includes(
                              specialty.id
                            )}
                            radio={true}
                            onClick={() => handleCheckChange({ specialty })}
                          />
                        )}
                        {specialty.icon && (
                          <i
                            className={`icon webtool-${specialty.icon.label}`}
                          />
                        )}
                        <span
                          className={styles.name}
                          onClick={() => {
                            if (currentType.hasChoices)
                              handleCheckChange({ specialty });
                          }}
                          style={{
                            cursor: currentType.hasChoices
                              ? "pointer"
                              : "default",
                          }}
                        >
                          {specialty[nameAttr]}
                        </span>
                      </div>
                      {specialty.specialties && (
                        <ul className={styles.specialtiesList}>
                          {specialty.specialties.map((subSpecialty) => (
                            <li
                              className={styles.subSpec}
                              style={{
                                opacity:
                                  !specialty.isMultiSelect &&
                                  !folderSpecialties?.list.includes(
                                    specialty.id
                                  )
                                    ? "0.5"
                                    : "1",
                              }}
                            >
                              <Checkbox
                                checked={folderSpecialties?.list.includes(
                                  subSpecialty.id
                                )}
                                radio={!specialty.isMultiSelect}
                                onClick={() =>
                                  handleCheckChange({
                                    subSpecialty,
                                    specialty,
                                  })
                                }
                              />
                              <span
                                onClick={() =>
                                  handleCheckChange({
                                    subSpecialty,
                                    specialty,
                                  })
                                }
                              >
                                {subSpecialty[nameAttr]}
                              </span>
                              {specialty.isMultiSelect && allowFavorites && (
                                <IconStar
                                  className={classNames(
                                    styles.star,
                                    folderSpecialties?.favorites.includes(
                                      subSpecialty.id
                                    )
                                      ? styles.active
                                      : ""
                                  )}
                                  onClick={() =>
                                    checkFavorite(subSpecialty.id, specialty)
                                  }
                                  filled={folderSpecialties?.favorites.includes(
                                    subSpecialty.id
                                  )}
                                />
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </>
      {/* )} */}
    </div>
  );
};

export const TeamAppPublic = ({
  handlePortalBlocChange,
  portals,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  sortCollaborators,
  collaborators,
  setCollaborators,
  inModal,
}) => {
  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <img src={`img/apps/team.svg`} alt="" />
            <label>{_("Team")}</label>
          </div>
          {/* <Switch
            name="team"
            isChecked={portals.public.config.team.activated}
            onChange={(e) => handlePortalBlocChange(e, "team")}
          /> */}
        </div>
      )}
      {/* {portals.public.config.team.activated && ( */}
      <>
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={portals.public.config.team[titleAttr(language.value)]}
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      "team"
                    )
                  }
                />

                <label
                  className={`${styles.configLabel} ${styles.descriptionLabel}`}
                >
                  {_(`description_${language.value}`)}
                </label>
                <textarea
                  rows="4"
                  className={styles.descriptionInput}
                  value={
                    portals.public.config.team[descriptionAttr(language.value)]
                  }
                  onChange={(e) =>
                    handlePortalBlocDescriptionChange(
                      language.value,
                      e.target.value,
                      "team"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
        <div
          className={classNames(
            styles.services,
            inModal ? styles.teamList : ""
          )}
        >
          <CollaboratorsSortableList
            onSortEnd={(e) => sortCollaborators(e)}
            axis={"xy"}
            collaborators={collaborators}
            setCollaborators={setCollaborators}
            distance={1}
            inModal={true}
          />
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export const ContactAppPublic = ({
  handlePortalBlocChange,
  portals,
  handleGeoChange,
  addPushPinOnClick,
  handleChangeSchedule,
  handleApplySchedule,
  contactEmailError,
  setContactEmailError,
  contactPhoneError,
  setContactPhoneError,
  inModal,
  community,
}) => {
  const renderTime = (val) => {
    return ["00:00", "", "__:__", "NO_WORK"].includes(val)
      ? val
      : val.replace(/_/g, "0");
  };
  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <img src={`img/apps/ebox.svg`} alt="" />
            <label>{_("Contact")}</label>
          </div>
          <Switch
            isChecked={portals.public.config.contact.activated}
            onChange={(e) => handlePortalBlocChange(e, "contact")}
          />
        </div>
      )}

      {portals.public.config.contact.activated && (
        <>
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <FormInput
                name="email"
                value={portals.public.config.contact.email}
                label={_("Email")}
                autoComplete="none"
                onChange={(e) => handleGeoChange(e, "email")}
                handleBlur={(e) => {
                  if (
                    e.target.value.length > 0 &&
                    !validateEmail(e.target.value)
                  ) {
                    setContactEmailError(_("validate_email"));
                  } else {
                    setContactEmailError();
                  }
                }}
                error={contactEmailError}
              />
            </div>

            <div className="cell small-12 medium-6">
              <FormInput
                name="phone"
                value={portals.public.config.contact.phone}
                label={_("phone_number")}
                autoComplete="none"
                onChange={(e) => handleGeoChange(e, "phone")}
                handleBlur={(e) => {
                  if (
                    e.target.value.length > 0 &&
                    !validatePhone(e.target.value).valid
                  ) {
                    setContactPhoneError(_("validate_phone"));
                  } else {
                    setContactPhoneError("");
                  }
                }}
                error={contactPhoneError}
              />
            </div>
          </div>

          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <FormInput
                name="latitude"
                value={portals.public.config.contact.latitude}
                label={_("Latitude")}
                autoComplete="none"
                onChange={(e) => handleGeoChange(e, "latitude")}
              />
            </div>

            <div className="cell small-12 medium-6">
              <FormInput
                name="longitude"
                value={portals.public.config.contact.longitude}
                label={_("Longitude")}
                autoComplete="none"
                onChange={(e) => handleGeoChange(e, "longitude")}
              />
            </div>
          </div>

          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <FormInput
                name="address"
                value={
                  portals.public.config.contact?.address ?? community?.address1
                }
                label={_("address")}
                autoComplete="none"
                onChange={(e) => handleGeoChange(e, "address")}
              />
            </div>

            <div className="cell small-12 medium-6 grid-x">
              <div className="cell small-12 medium-6">
                <div className="mr-2">
                  <FormInput
                    name="zipCode"
                    value={
                      portals.public.config.contact?.zipCode ??
                      community?.zipCode
                    }
                    label={_("zipCode")}
                    autoComplete="none"
                    onChange={(e) => handleGeoChange(e, "zipCode")}
                  />
                </div>
              </div>
              <div className="cell small-12 medium-6 ">
                <FormInput
                  name="city"
                  value={portals.public.config.contact?.city ?? community?.city}
                  label={_("city")}
                  autoComplete="none"
                  onChange={(e) => handleGeoChange(e, "city")}
                />
              </div>
            </div>
          </div>

          <div className={styles.mapbox}>
            <ReactBingmaps
              bingmapKey="AlfqRT9EH4KtKEzZQp4yhxAIWxiujabG1jpSuUJaQ_GWxN9ONI_MQvd_e2kJpK5H"
              zoom={14}
              mapTypeId={"aerial"}
              mapOptions={{ disableScrollWheelZoom: true }}
              center={[
                parseFloat(portals.public.config.contact.latitude),
                parseFloat(portals.public.config.contact.longitude),
              ]}
              navigationBarMode={"compact"}
              getLocation={{
                addHandler: "click",
                callback: addPushPinOnClick,
              }}
              pushPins={[
                {
                  location: [
                    parseFloat(portals.public.config.contact.latitude),
                    parseFloat(portals.public.config.contact.longitude),
                  ],
                  option: {
                    color: "#18A0FB",
                    icon: "https://unpkg.com/leaflet@1.3.3/dist/images/marker-icon.png",
                  },
                },
              ]}
            />
          </div>

          <div className={styles.schedule}>
            <div className={styles.schedule_row}>
              <div
                className={`${styles.schedule_header} ${styles.schedule_day}`}
              >
                {_("Day")}
              </div>
              <div
                className={`${styles.schedule_header} ${styles.schedule_hours}`}
              >
                {_("Morning")}
              </div>
              <div
                className={`${styles.schedule_header} ${styles.schedule_hours}`}
              >
                {_("Afternoon")}
              </div>
              <div className={`${styles.schedule_action}`}> </div>
            </div>
            {days.map((day) => {
              return (
                <div className={styles.schedule_row} key={`day${day}`}>
                  <div className={`${styles.schedule_day}`}>{_(`${day}`)}</div>
                  <div className={`${styles.schedule_hours}`}>
                    <div className={styles.schedule_input}>
                      <TimeInput
                        value={renderTime(
                          portals.public.config.contact.schedule[day].MORNING
                            .FROM
                        )}
                        onChange={(e) =>
                          handleChangeSchedule(
                            e.target.value,
                            day,
                            "MORNING",
                            "FROM"
                          )
                        }
                      />
                      <span>{_("to")}</span>
                      <TimeInput
                        value={renderTime(
                          portals.public.config.contact.schedule[day].MORNING.TO
                        )}
                        onChange={(e) =>
                          handleChangeSchedule(
                            e.target.value,
                            day,
                            "MORNING",
                            "TO"
                          )
                        }
                      />
                    </div>
                    <div className={styles.schedule_nowork}>
                      <Switch
                        isChecked={
                          portals.public.config.contact.schedule[day].MORNING
                            .FROM === "NO_WORK"
                        }
                        onChange={() =>
                          handleChangeSchedule(
                            "NO_WORK",
                            day,
                            "MORNING",
                            "FROM"
                          )
                        }
                      />
                      <span>{_("No work")}</span>
                    </div>
                  </div>
                  <div className={`${styles.schedule_hours}`}>
                    <div className={styles.schedule_input}>
                      <TimeInput
                        value={renderTime(
                          portals.public.config.contact.schedule[day].AFTERNOON
                            .FROM
                        )}
                        onChange={(e) =>
                          handleChangeSchedule(
                            e.target.value,
                            day,
                            "AFTERNOON",
                            "FROM"
                          )
                        }
                      />
                      <span>{_("to")}</span>
                      <TimeInput
                        value={renderTime(
                          portals.public.config.contact.schedule[day].AFTERNOON
                            .TO
                        )}
                        onChange={(e) =>
                          handleChangeSchedule(
                            e.target.value,
                            day,
                            "AFTERNOON",
                            "TO"
                          )
                        }
                      />
                    </div>
                    <div className={styles.schedule_nowork}>
                      <Switch
                        isChecked={
                          portals.public.config.contact.schedule[day].AFTERNOON
                            .FROM === "NO_WORK"
                        }
                        onChange={() =>
                          handleChangeSchedule(
                            "NO_WORK",
                            day,
                            "AFTERNOON",
                            "FROM"
                          )
                        }
                      />
                      <span>{_("No work")}</span>
                    </div>
                  </div>
                  <div className={`${styles.schedule_action}`}>
                    {day === "MON" && (
                      <span
                        className={styles.schedule_btn}
                        onClick={handleApplySchedule}
                      >
                        {_("Apply_for_all_days")}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export const ServicesAppPublic = ({
  handlePortalBlocChange,
  portals,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  services,
  setServices,
  sortServices,
  maxPicks,
  version = null,
  inModal,
}) => {
  const token = useSelector((state) => state.auth.token);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const afterServiceSave = (val) => {
    if (services.map((el) => el.id).includes(val.id)) {
      setServices(
        services.map((el) => {
          if (el.id === val.id) {
            return val;
          }
          return el;
        })
      );
    } else {
      setServices([val, ...services]);
    }
  };

  const onDelete = (val) => {
    setServiceToDelete(val);
    setDeleteModalOpen(true);
  };
  const onEdit = (val) => {
    setCurrentService(val);
    setOpenServiceModal(true);
  };

  const allowAdd = () => {
    if (maxPicks === -1) {
      return true;
    }
    return !(maxPicks < services.filter((el) => el.show).length + 1);
  };

  const onHide = (val) => {
    setServices(
      services.map((el) => {
        if (el.id === val.id) {
          return el.show || (!el.show && allowAdd())
            ? { ...el, show: !el.show }
            : el;
        }
        return el;
      })
    );
  };

  const serviceDelete = async () => {
    try {
      setDeleting(true);
      await deleteService(token, serviceToDelete.id);
      setServices(services.filter((el) => el.id !== serviceToDelete.id));
      setServiceToDelete(null);
      setDeleting(false);
      setDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <label>{_("Services")}</label>
          </div>
          <Switch
            isChecked={portals.public.config.services.activated}
            onChange={(e) => handlePortalBlocChange(e, "services")}
          />
        </div>
      )}
      {/* {portals.public.config.services.activated && ( */}
      <>
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={
                    portals.public.config.services[titleAttr(language.value)]
                  }
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      "services"
                    )
                  }
                />

                <label
                  className={`${styles.configLabel} ${styles.descriptionLabel}`}
                >
                  {_(`description_${language.value}`)}
                </label>
                <textarea
                  rows="4"
                  className={styles.descriptionInput}
                  value={
                    portals.public.config.services[
                      descriptionAttr(language.value)
                    ]
                  }
                  onChange={(e) =>
                    handlePortalBlocDescriptionChange(
                      language.value,
                      e.target.value,
                      "services"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
        <Button
          className={styles.addMedia}
          size={"sm"}
          onClick={() => setOpenServiceModal(true)}
        >
          {_("add_service")}
        </Button>
        <ServicesSortableList
          services={services}
          onDelete={onDelete}
          onEdit={onEdit}
          onHide={onHide}
          axis={"xy"}
          distance={1}
          onSortEnd={(e) => sortServices(e)}
        />
        <ModalConfirm
          type="delete"
          isOpen={deleteModalOpen}
          onCancel={() => setDeleteModalOpen(false)}
          onConfirm={serviceDelete}
          inProcess={deleting}
          title={_("delete_confirm")}
          text={_("text_delete_collaborator")}
          labelNo={_("no")}
          labelYes={_("yes")}
        />
        <ServicePopOver
          languages={languages}
          isOpen={openServiceModal}
          onClose={() => setOpenServiceModal(false)}
          afterServiceSave={afterServiceSave}
          currentElement={currentService}
          setCurrentElement={setCurrentService}
          hasPageConfig={false}
        />
      </>
      {/* )} */}
    </div>
  );
};

export const FaqsAppPublic = ({
  handlePortalBlocChange,
  portals,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  titleAttr,
  handlePortalBlocTitleChange,
  faqs,
  setFaqs,
  sortFaqs,
  maxPicks,
  inModal,
}) => {
  const token = useSelector((state) => state.auth.token);
  const [openModal, setOpenModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const afterSave = (val) => {
    if (faqs.map((el) => el.id).includes(val.id)) {
      setFaqs(
        faqs.map((el) => {
          if (el.id === val.id) {
            return val;
          }
          return el;
        })
      );
    } else {
      setFaqs([val, ...faqs]);
    }
  };

  const onDelete = (val) => {
    setItemToDelete(val);
    setDeleteModalOpen(true);
  };
  const onEdit = (val) => {
    setCurrentItem(val);
    setOpenModal(true);
  };

  const allowAdd = () => {
    if (maxPicks === -1) {
      return true;
    }
    return !(maxPicks < faqs.filter((el) => el.show).length + 1);
  };

  const onHide = (val) => {
    setFaqs(
      faqs.map((el) => {
        if (el.id === val.id) {
          return el.show || (!el.show && allowAdd())
            ? { ...el, show: !el.show }
            : el;
        }
        return el;
      })
    );
  };

  const faqDelete = async () => {
    try {
      setDeleting(true);
      await deleteFaq(token, itemToDelete.id);
      setFaqs(faqs.filter((el) => el.id !== itemToDelete.id));
      setItemToDelete(null);
      setDeleting(false);
      setDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <label>{_("Faq")}</label>
          </div>
          {/* <Switch
            isChecked={portals.public.config.faqs.activated}
            onChange={(e) => handlePortalBlocChange(e, "faqs")}
          /> */}
        </div>
      )}
      {/* {portals.public.config.faqs.activated && ( */}
      <>
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={portals.public.config.faqs[titleAttr(language.value)]}
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      "faqs"
                    )
                  }
                />

                <label
                  className={`${styles.configLabel} ${styles.descriptionLabel}`}
                >
                  {_(`description_${language.value}`)}
                </label>
                <textarea
                  rows="4"
                  className={styles.descriptionInput}
                  value={
                    portals.public.config.faqs[descriptionAttr(language.value)]
                  }
                  onChange={(e) =>
                    handlePortalBlocDescriptionChange(
                      language.value,
                      e.target.value,
                      "faqs"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
        <Button
          className={styles.addMedia}
          size={"sm"}
          onClick={() => setOpenModal(true)}
        >
          {_("add_faq")}
        </Button>

        <FaqsSortableList
          faqs={faqs}
          onDelete={onDelete}
          onEdit={onEdit}
          onHide={onHide}
          distance={1}
          onSortEnd={(e) => sortFaqs(e)}
        />

        <ModalConfirm
          type="delete"
          isOpen={deleteModalOpen}
          onCancel={() => setDeleteModalOpen(false)}
          onConfirm={faqDelete}
          inProcess={deleting}
          title={_("delete_confirm")}
          text={_("text_delete_collaborator")}
          labelNo={_("no")}
          labelYes={_("yes")}
        />

        <FaqModal
          languages={languages}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          afterSave={afterSave}
          currentElement={currentItem}
          setCurrentElement={setCurrentItem}
        />
      </>
      {/* )} */}
    </div>
  );
};

export const TestimonialsAppPublic = ({
  handlePortalBlocChange,
  portals,
  testimonials,
  setTestimonials,
  sortTestimonials,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  maxPicks,
  inModal,
}) => {
  const token = useSelector((state) => state.auth.token);
  const [openModal, setOpenModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const afterSave = (val) => {
    if (testimonials.map((el) => el.id).includes(val.id)) {
      setTestimonials(
        testimonials.map((el) => {
          if (el.id === val.id) {
            return val;
          }
          return el;
        })
      );
    } else {
      setTestimonials([val, ...testimonials]);
    }
  };

  const onDelete = (val) => {
    setItemToDelete(val);
    setDeleteModalOpen(true);
  };
  const onEdit = (val) => {
    setCurrentItem(val);
    setOpenModal(true);
  };

  const allowAdd = () => {
    if (maxPicks === -1) {
      return true;
    }
    return !(maxPicks < testimonials.filter((el) => el.show).length + 1);
  };

  const onHide = (val) => {
    setTestimonials(
      testimonials.map((el) => {
        if (el.id === val.id) {
          return el.show || (!el.show && allowAdd())
            ? { ...el, show: !el.show }
            : el;
        }
        return el;
      })
    );
  };

  const testimonialDelete = async () => {
    try {
      setDeleting(true);
      await deleteTestimonial(token, itemToDelete.id);
      setTestimonials(testimonials.filter((el) => el.id !== itemToDelete.id));
      setItemToDelete(null);
      setDeleting(false);
      setDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <label>{_("testimonials")}</label>
          </div>
          {/* <Switch
            isChecked={portals.public.config.testimonials.activated}
            onChange={(e) => handlePortalBlocChange(e, "testimonials")}
          /> */}
        </div>
      )}
      {/* {portals.public.config.testimonials.activated && ( */}
      <div className={styles.testimonial_content}>
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={
                    portals.public.config.testimonials[
                      titleAttr(language.value)
                    ]
                  }
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      "testimonials"
                    )
                  }
                />

                <label
                  className={`${styles.configLabel} ${styles.descriptionLabel}`}
                >
                  {_(`description_${language.value}`)}
                </label>
                <textarea
                  rows="4"
                  className={styles.descriptionInput}
                  value={
                    portals.public.config.testimonials[
                      descriptionAttr(language.value)
                    ]
                  }
                  onChange={(e) =>
                    handlePortalBlocDescriptionChange(
                      language.value,
                      e.target.value,
                      "testimonials"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
        <Button
          className={styles.addMedia}
          size={"sm"}
          onClick={() => setOpenModal(true)}
        >
          {_("add_testimonial")}
        </Button>
        <TestimonialsSortableList
          testimonials={testimonials}
          onDelete={onDelete}
          onEdit={onEdit}
          onHide={onHide}
          distance={1}
          onSortEnd={(e) => sortTestimonials(e)}
        />

        <ModalConfirm
          type="delete"
          isOpen={deleteModalOpen}
          onCancel={() => setDeleteModalOpen(false)}
          onConfirm={testimonialDelete}
          inProcess={deleting}
          title={_("delete_confirm")}
          text={_("text_delete_collaborator")}
          labelNo={_("no")}
          labelYes={_("yes")}
        />

        <TestimonialModal
          languages={languages}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          afterSave={afterSave}
          currentElement={currentItem}
          setCurrentElement={setCurrentItem}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export const PartnersAppPublic = ({
  handlePortalBlocChange,
  portals,
  partners,
  setPartners,
  titleAttr,
  handlePortalBlocTitleChange,
  maxPicks,
  inModal,
}) => {
  const fileInput = useRef(null);
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [uploading, setUploading] = useState(false);
  const [tmp, setTmp] = useState("");

  const uploadFile = async (file) => {
    try {
      setUploading(true);
      const res = await saveMedia({
        token,
        community: fiduciary,
        file,
      });
      setTmp("");
      setPartners([...partners, res.data.data.fullMediaUrl]);
      setUploading(false);
    } catch (error) {
      setUploading(false);
      console.error(error);
    }
  };

  const allowAdd = () => {
    if (maxPicks === -1) {
      return true;
    }
    return !(maxPicks < partners.length + 1);
  };

  const onDelete = (val) => {
    setPartners(partners.filter((el) => el !== val.image));
  };

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <label>{_("partners")}</label>
          </div>
          <Switch
            isChecked={portals.public.config.partners.activated}
            onChange={(e) => handlePortalBlocChange(e, "partners")}
          />
        </div>
      )}
      {/* {portals.public.config.partners.activated && ( */}
      <>
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={
                    portals.public.config.partners[titleAttr(language.value)]
                  }
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      "partners"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
        {allowAdd() && (
          <Button
            className={styles.addMedia}
            size={"sm"}
            onClick={() => fileInput?.current?.click()}
            disabled={uploading}
          >
            {_(uploading ? "uploading" : "add_partner")}
          </Button>
        )}
        {partners.length > 0 && (
          <div className={classNames(styles.mediasList, "grid-x")}>
            {partners.map((media) => (
              <div
                key={media.id}
                className={classNames(
                  styles.mediaCont,
                  "cell small-12 medium-6"
                )}
              >
                <Service
                  service={{
                    image: media,
                  }}
                  onDelete={onDelete}
                  onEdit={null}
                  onHide={null}
                />
              </div>
            ))}
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          ref={fileInput}
          style={{ display: "none" }}
          onChange={(e) => {
            setTmp(e.target.value);
            uploadFile(e.target.files[0]);
          }}
          value={tmp}
        />
      </>
      {/* )} */}
    </div>
  );
};

export const NumbersAppPublic = ({
  handlePortalBlocChange,
  portals,
  numbersList,
  setNumbersList,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  maxPicks,
  allowImg,
  numbersImage,
  setNumbersImage,
  inModal,
}) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [addMediaModalOpened, setAddMediaModalOpened] = useState(false);

  const allowAdd = () => {
    if (maxPicks === -1) {
      return true;
    }
    return !(maxPicks < numbersList.length + 1);
  };

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <label>{_("in_a_few_numbers")}</label>
          </div>
          <Switch
            isChecked={portals.public.config.numbers.activated}
            onChange={(e) => handlePortalBlocChange(e, "numbers")}
          />
        </div>
      )}
      {/* {portals.public.config.numbers.activated && ( */}
      <>
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={
                    portals.public.config.numbers[titleAttr(language.value)]
                  }
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      "numbers"
                    )
                  }
                />
                <label
                  className={`${styles.configLabel} ${styles.descriptionLabel}`}
                >
                  {_(`description_${language.value}`)}
                </label>
                <textarea
                  rows="4"
                  className={styles.descriptionInput}
                  value={
                    portals.public.config.numbers[
                      descriptionAttr(language.value)
                    ]
                  }
                  onChange={(e) =>
                    handlePortalBlocDescriptionChange(
                      language.value,
                      e.target.value,
                      "numbers"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
        {allowImg && (
          <>
            <div
              className={styles.dropZone}
              style={{
                backgroundImage: `url(${
                  numbersImage ? numbersImage.fullMediaUrl : ""
                })`,
                marginBottom: "1rem",
              }}
              onClick={() => setAddMediaModalOpened(true)}
            >
              {!numbersImage && (
                <>
                  <div className={styles.text}>
                    <span>{_("image_import_text")}</span>
                  </div>
                </>
              )}
            </div>
            <AddSingleMedia
              isOpen={addMediaModalOpened}
              close={() => {
                setAddMediaModalOpened(false);
              }}
              token={auth.token}
              community={fiduciary}
              setImage={setNumbersImage}
            />
          </>
        )}
        {allowAdd() && (
          <Button
            className={styles.addMedia}
            size={"sm"}
            onClick={() =>
              setNumbersList([
                ...numbersList,
                {
                  id: uuidv4(),
                  title: "",
                  description: "",
                },
              ])
            }
          >
            {_("add_new_number")}
          </Button>
        )}
        {numbersList.length > 0 &&
          numbersList.map((item) => (
            <div key={item.id} className={styles.numberForm}>
              <input
                className={classNames(styles.formInput, styles.title)}
                placeholder={_("title")}
                value={item.title}
                onChange={(e) => {
                  setNumbersList(
                    numbersList.map((el) => {
                      if (el.id === item.id) {
                        return {
                          ...el,
                          title: e.target.value,
                        };
                      }
                      return el;
                    })
                  );
                }}
              />
              <input
                className={classNames(styles.formInput, styles.description)}
                placeholder={_("description")}
                value={item.description}
                onChange={(e) => {
                  setNumbersList(
                    numbersList.map((el) => {
                      if (el.id === item.id) {
                        return {
                          ...el,
                          description: e.target.value,
                        };
                      }
                      return el;
                    })
                  );
                }}
              />
              <button
                onClick={() => {
                  setNumbersList(numbersList.filter((el) => el.id !== item.id));
                }}
              >
                <IconTrash />
              </button>
            </div>
          ))}
      </>
      {/* )} */}
    </div>
  );
};

export const PacksAppPublic = ({
  handlePortalBlocChange,
  portals,
  packsList,
  setPacksList,
  featuresCategoriesList,
  setFeaturesCategoriesList,
  titleAttr,
  descriptionAttr,
}) => {
  const [hideFeatures, setHideFeatures] = useState([]);
  const lng = useSelector((state) => state.params.language);

  const allFeaturesList = () => {
    return featuresCategoriesList
      .map((category) => {
        if (category.features) {
          return category.features;
        }
        return [];
      })
      .flat(Infinity);
  };

  const initialFeatures = () => {
    return featuresCategoriesList
      .map((category) => {
        if (category.features) {
          return category.features.map((el) => {
            return {
              id: el.id,
              categoryId: el.categoryId,
              available: false,
              value: "",
            };
          });
        }
        return [];
      })
      .flat(Infinity);
  };

  const addPack = () => {
    setPacksList([
      ...packsList,
      {
        id: uuidv4(),
        titleFr: "",
        titleEn: "",
        titleNl: "",
        descriptionFr: "",
        descriptionEn: "",
        descriptionNl: "",
        monthlyPrice: "",
        yearlyPrice: "",
        features: [...initialFeatures()],
      },
    ]);
  };

  const addFeatureCategory = () => {
    setFeaturesCategoriesList([
      ...featuresCategoriesList,
      {
        id: uuidv4(),
        titleFr: "",
        titleEn: "",
        titleNl: "",
        features: [],
      },
    ]);
  };

  const addFeature = (categoryID) => {
    let newFeature = {
      id: uuidv4(),
      titleFr: "",
      titleEn: "",
      titleNl: "",
      categoryId: categoryID,
    };
    setFeaturesCategoriesList(
      featuresCategoriesList.map((category) => {
        if (category.id === categoryID) {
          return {
            ...category,
            features: [...category.features, newFeature],
          };
        }
        return category;
      })
    );

    setPacksList(
      packsList.map((pack) => {
        return {
          ...pack,
          features: [
            ...pack.features,
            {
              id: newFeature.id,
              categoryId: newFeature.categoryId,
              available: false,
              value: "",
            },
          ],
        };
      })
    );
  };

  const deleteFeatureCategory = (categoryID) => {
    setFeaturesCategoriesList(
      featuresCategoriesList.filter((el) => el.id !== categoryID)
    );

    setPacksList(
      packsList.map((pack) => {
        return {
          ...pack,
          features: pack.features.filter((el) => el.categoryId !== categoryID),
        };
      })
    );
  };

  const deleteFeature = (categoryID, featureID) => {
    setFeaturesCategoriesList(
      featuresCategoriesList.map((category) => {
        if (category.id === categoryID) {
          return {
            ...category,
            features: category.features.filter((el) => el.id !== featureID),
          };
        }
        return category;
      })
    );

    setPacksList(
      packsList.map((pack) => {
        return {
          ...pack,
          features: pack.features.filter((el) => el.id !== featureID),
        };
      })
    );
  };

  const deletePack = (packID) => {
    setPacksList(packsList.filter((el) => el.id !== packID));
  };

  const changeAvailability = (featureID, packID) => {
    // change availability of feature in pack
    setPacksList(
      packsList.map((pack) => {
        if (pack.id === packID) {
          return {
            ...pack,
            features: pack.features.map((el) => {
              if (el.id === featureID) {
                return {
                  ...el,
                  available: !el.available,
                  value: !el.available === false ? "" : el.value,
                };
              }
              return el;
            }),
          };
        }
        return pack;
      })
    );
  };

  const changeValue = (featureID, packID, value) => {
    // change value of feature in pack
    setPacksList(
      packsList.map((pack) => {
        if (pack.id === packID) {
          return {
            ...pack,
            features: pack.features.map((el) => {
              if (el.id === featureID) {
                return {
                  ...el,
                  value: value,
                };
              }
              return el;
            }),
          };
        }
        return pack;
      })
    );
  };

  const changePackTitle = (packID, value, lang) => {
    console.log(packID, value, lang);
    setPacksList(
      packsList.map((el) => {
        if (el.id === packID) {
          return {
            ...el,
            [titleAttr(lang)]: value,
          };
        }
        return el;
      })
    );
  };

  const changePackDescription = (packID, value, lang) => {
    setPacksList(
      packsList.map((el) => {
        if (el.id === packID) {
          return {
            ...el,
            [descriptionAttr(lang)]: value,
          };
        }
        return el;
      })
    );
  };

  const changeCategoryTitle = (categoryID, value, lang) => {
    setFeaturesCategoriesList(
      featuresCategoriesList.map((category) => {
        if (category.id === categoryID) {
          return {
            ...category,
            [titleAttr(lang)]: value,
          };
        }
        return category;
      })
    );
  };

  const changeFeatureTitle = (categoryID, featureID, value, lang) => {
    setFeaturesCategoriesList(
      featuresCategoriesList.map((category) => {
        if (category.id === categoryID) {
          return {
            ...category,
            features: category.features.map((el) => {
              if (el.id === featureID) {
                return {
                  ...el,
                  [titleAttr(lang)]: value,
                };
              }
              return el;
            }),
          };
        }
        return category;
      })
    );
  };

  const changePackMonthlyPrice = (packID, value) => {
    setPacksList(
      packsList.map((el) => {
        if (el.id === packID) {
          return {
            ...el,
            monthlyPrice: value,
          };
        }
        return el;
      })
    );
  };
  const changePackYearlyPrice = (packID, value) => {
    setPacksList(
      packsList.map((el) => {
        if (el.id === packID) {
          return {
            ...el,
            yearlyPrice: value,
          };
        }
        return el;
      })
    );
  };

  const groupByCat = (listFeatures) => {
    const catIDs = featuresCategoriesList.map((el) => el.id);
    return catIDs
      .map((id) => {
        return listFeatures.filter((feature) => feature.categoryId === id);
      })
      .filter((el) => el.length > 0);
  };

  return (
    <div className={styles.portalBloc}>
      <div className={styles.portalBloc_top}>
        <div className={styles.portalBloc_top_left}>
          <label>{_("packs")}</label>
        </div>
      </div>
      <div className={styles.features_section}>
        <div className={styles.title}>{_("features_management")}</div>

        <Button
          className={styles.addMedia}
          size={"sm"}
          onClick={() => addFeatureCategory()}
        >
          {_("add_feature_category")}
        </Button>

        <div className={styles.categories_list}>
          {featuresCategoriesList.map((category) => (
            <div className={styles.category} key={category.id}>
              <div className={styles.flex}>
                {_("category_details")}
                <Button
                  variant={"danger"}
                  className={styles.addMedia}
                  size={"sm"}
                  onClick={() => deleteFeatureCategory(category.id)}
                >
                  {_("remove_feature_category")}
                </Button>
              </div>

              <Tabs theme="wtheme">
                {languages.map((language) => (
                  <div
                    key={`langtab${language.value}`}
                    label={language.label}
                    icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                  >
                    <div className={styles.langTab}>
                      <label className={styles.configLabel}>
                        {_(`title_${language.value}`)}
                      </label>

                      <input
                        className={styles.formInput}
                        value={category[titleAttr(language.value)]}
                        onChange={(e) =>
                          changeCategoryTitle(
                            category.id,
                            e.target.value,
                            language.value
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
              </Tabs>
              <div className={styles.flex}>
                {_("features_list")}
                <Button
                  className={styles.addMedia}
                  size={"sm"}
                  onClick={() => addFeature(category.id)}
                >
                  {_("add_feature")}
                </Button>
                <Button
                  className={classNames(
                    styles.arrowBtn,
                    hideFeatures.includes(category.id)
                      ? styles.arrowBtn_rotate
                      : ""
                  )}
                  variant={"secondary"}
                  size={"sm"}
                  onClick={() =>
                    setHideFeatures(
                      hideFeatures.includes(category.id)
                        ? hideFeatures.filter((el) => el !== category.id)
                        : [...hideFeatures, category.id]
                    )
                  }
                >
                  <IconArrowDown />
                </Button>
              </div>
              {!hideFeatures.includes(category.id) && (
                <div className={styles.features}>
                  {category.features.map((feature) => (
                    <div className={styles.feature} key={feature.id}>
                      <Button
                        variant={"danger"}
                        className={styles.addMedia}
                        size={"sm"}
                        onClick={() => deleteFeature(category.id, feature.id)}
                      >
                        {_("remove_feature")}
                      </Button>

                      <Tabs theme="wtheme">
                        {languages.map((language) => (
                          <div
                            key={`langtab${language.value}`}
                            label={language.label}
                            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                          >
                            <div className={styles.langTab}>
                              <label className={styles.configLabel}>
                                {_(`title_${language.value}`)}
                              </label>

                              <input
                                className={styles.formInput}
                                value={feature[titleAttr(language.value)]}
                                onChange={(e) =>
                                  changeFeatureTitle(
                                    category.id,
                                    feature.id,
                                    e.target.value,
                                    language.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </Tabs>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.packs_section}>
        <div className={styles.title}>{_("packs_management")}</div>
        <Button
          className={styles.addMedia}
          size={"sm"}
          onClick={() => addPack()}
        >
          {_("add_pack")}
        </Button>
        <div className={styles.packs}>
          {packsList.map((pack) => (
            <div className={styles.pack} key={pack.id}>
              <div className={styles.flex}>
                {_("pack_details")}
                <Button
                  variant={"danger"}
                  className={styles.addMedia}
                  size={"sm"}
                  onClick={() => deletePack(pack.id)}
                >
                  {_("remove_pack")}
                </Button>
              </div>
              <Tabs theme="wtheme">
                {languages.map((language) => (
                  <div
                    key={`langtab${language.value}`}
                    label={language.label}
                    icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                  >
                    <div className={styles.langTab}>
                      <label className={styles.configLabel}>
                        {_(`title_${language.value}`)}
                      </label>

                      <input
                        className={styles.formInput}
                        value={pack[titleAttr(language.value)]}
                        onChange={(e) =>
                          changePackTitle(
                            pack.id,
                            e.target.value,
                            language.value
                          )
                        }
                      />

                      <label
                        className={`${styles.configLabel} ${styles.descriptionLabel}`}
                      >
                        {_(`description_${language.value}`)}
                      </label>
                      <textarea
                        rows="4"
                        className={styles.descriptionInput}
                        value={pack[descriptionAttr(language.value)]}
                        onChange={(e) =>
                          changePackDescription(
                            pack.id,
                            e.target.value,
                            language.value
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
              </Tabs>

              <div className={styles.priceForm}>
                <div className={styles.row}>
                  <label className={styles.configLabel}>
                    {_(`monthly_price`)}
                  </label>
                  <input
                    type="number"
                    className={styles.formInput}
                    value={pack.monthlyPrice}
                    onChange={(e) =>
                      changePackMonthlyPrice(pack.id, e.target.value)
                    }
                  />
                </div>
                <div className={styles.row}>
                  <label className={styles.configLabel}>
                    {_(`yearly_price`)}
                  </label>
                  <input
                    type="number"
                    className={styles.formInput}
                    value={pack.yearlyPrice}
                    onChange={(e) =>
                      changePackYearlyPrice(pack.id, e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={styles.flex}>
                {_("pack_features")}
                <Button
                  className={classNames(
                    styles.arrowBtn,
                    hideFeatures.includes(pack.id) ? styles.arrowBtn_rotate : ""
                  )}
                  variant={"secondary"}
                  size={"sm"}
                  onClick={() =>
                    setHideFeatures(
                      hideFeatures.includes(pack.id)
                        ? hideFeatures.filter((el) => el !== pack.id)
                        : [...hideFeatures, pack.id]
                    )
                  }
                >
                  <IconArrowDown />
                </Button>
              </div>
              {!hideFeatures.includes(pack.id) && (
                <div className={styles.features}>
                  {groupByCat(pack.features).map((featureList) => (
                    <div className={styles.catList}>
                      <div className={styles.catName}>
                        {
                          featuresCategoriesList.filter(
                            (cat) => cat.id === featureList[0].categoryId
                          )[0][titleAttr(lng)]
                        }
                      </div>
                      {featureList.map((feature) => (
                        <div className={styles.feature} key={feature.id}>
                          <div className={styles.name}>
                            {
                              allFeaturesList().filter(
                                (el) => el.id === feature.id
                              )[0][titleAttr(lng)]
                            }
                          </div>
                          <div className={styles.actions}>
                            <Switch
                              isChecked={feature.available}
                              onChange={(e) =>
                                changeAvailability(feature.id, pack.id)
                              }
                            />
                            <input
                              disabled={!feature.available}
                              className={classNames(
                                styles.formInput,
                                styles.value
                              )}
                              value={feature.value}
                              onChange={(e) =>
                                changeValue(feature.id, pack.id, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const NewsletterAppPublic = ({
  editSection,
  handlePortalBlocChange,
  portals,
  aboutImage,
  setAboutImage,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  handleAboutPageBlocChange,
  handleAboutPageBlocTitleChange,
  handleAboutPageBlocDescriptionChange,
  valuesList,
  setValuesList,
  valuesVideo,
  setValuesVideo,
  galleryMedias,
  setGalleryMedias,
  showPageConfig = false,
  inModal,
}) => {
  const fileInput = useRef();
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [addMediaModalOpened, setAddMediaModalOpened] = useState(false);
  const [galleryModalOpened, setGalleryModalOpened] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [tmp, setTmp] = useState("");

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {/* {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <label>{_("about")}</label>
          </div>
          <Switch
            isChecked={portals.public.config.about.activated}
            onChange={(e) => handlePortalBlocChange(e, "about")}
          />
        </div>
      )} */}
      {portals.public.config.newsletter.activated && (
        <>
          <Tabs theme="wtheme">
            {languages.map((language) => (
              <div
                key={`langtab${language.value}`}
                label={language.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
              >
                <div className={styles.langTab}>
                  <label className={styles.configLabel}>
                    {_(`title_${language.value}`)}
                  </label>

                  <input
                    className={styles.formInput}
                    value={
                      portals.public.config.newsletter[
                        titleAttr(language.value)
                      ]
                    }
                    onChange={(e) =>
                      handlePortalBlocTitleChange(
                        language.value,
                        e.target.value,
                        "newsletter"
                      )
                    }
                  />

                  <label
                    className={`${styles.configLabel} ${styles.descriptionLabel}`}
                  >
                    {_(`description_${language.value}`)}
                  </label>
                  <textarea
                    rows="4"
                    className={styles.descriptionInput}
                    value={
                      portals.public.config.newsletter[
                        descriptionAttr(language.value)
                      ]
                    }
                    onChange={(e) =>
                      handlePortalBlocDescriptionChange(
                        language.value,
                        e.target.value,
                        "newsletter"
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </Tabs>
        </>
      )}
    </div>
  );
};

export const BannerAppPublic = ({
  editSection,
  handlePortalBlocChange,
  portals,
  aboutImage,
  setAboutImage,
  data,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  handleAboutPageBlocChange,
  handleAboutPageBlocTitleChange,
  handleAboutPageBlocDescriptionChange,
  valuesList,
  setValuesList,
  valuesVideo,
  setValuesVideo,
  galleryMedias,
  setGalleryMedias,
  showPageConfig = false,
  inModal,
  page,
}) => {
  const fileInput = useRef();
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [addMediaModalOpened, setAddMediaModalOpened] = useState(false);
  const [galleryModalOpened, setGalleryModalOpened] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [tmp, setTmp] = useState("");

  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      <div className={styles.section}>
        <div className={styles.top}>
          <div className={styles.name}>{_("banner")}</div>
        </div>
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={data?.[page]?.banner?.[titleAttr(language.value)]}
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      page + "|banner"
                    )
                  }
                />

                <label
                  className={`${styles.configLabel} ${styles.descriptionLabel}`}
                >
                  {_(`description_${language.value}`)}
                </label>
                <textarea
                  rows="4"
                  className={styles.descriptionInput}
                  value={
                    data?.[page]?.banner?.[descriptionAttr(language.value)]
                  }
                  onChange={(e) =>
                    handlePortalBlocDescriptionChange(
                      language.value,
                      e.target.value,
                      page + "|banner"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export const HistoryAppPublic = ({
  data,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  inModal,
}) => {
  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      <div className={styles.section}>
        <div className={styles.top}>
          <div className={styles.name}>{_("your_history")}</div>
        </div>
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={data?.[titleAttr(language.value)]}
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      "history"
                    )
                  }
                />

                <label
                  className={`${styles.configLabel} ${styles.descriptionLabel}`}
                >
                  {_(`description_${language.value}`)}
                </label>
                <textarea
                  rows="4"
                  className={styles.descriptionInput}
                  value={data?.[descriptionAttr(language.value)]}
                  onChange={(e) =>
                    handlePortalBlocDescriptionChange(
                      language.value,
                      e.target.value,
                      "history"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export const ValuesAppPublic = ({
  editSection,
  portals,
  setPortals,
  titleAttr,
  descriptionAttr,
  handlePortalBlocTitleChange,
  handlePortalBlocDescriptionChange,
  handleAboutPageBlocChange,
  handleAboutPageBlocTitleChange,
  handleAboutPageBlocDescriptionChange,
  valuesList,
  setValuesList,
  valuesVideo,
  setValuesVideo,
  inModal,
}) => {
  const fileInput = useRef();
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [isUploading, setIsUploading] = useState(false);
  const [tmp, setTmp] = useState("");
  const [error, setError] = useState("");

  // const handleValuesListChange = (list) => {
  //   const values = portals.public.config?.values ?? {};
  //   setPortals({
  //     ...portals,
  //     public: {
  //       ...portals.public,
  //       config: {
  //         ...portals.public.config,
  //         values: {
  //           ...values,
  //           list: list,
  //         },
  //       },
  //     },
  //   });
  // };

  // const setValuesVideo = (video) => {
  //   const values = portals.public.config?.values ?? {};
  //   setPortals({
  //     ...portals,
  //     public: {
  //       ...portals.public,
  //       config: {
  //         ...portals.public.config,
  //         values: {
  //           ...values,
  //           video: video,
  //         },
  //       },
  //     },
  //   });
  // };

  const uploadFile = async (file) => {
    setIsUploading(true);
    try {
      const res = await saveMedia({
        token: auth.token,
        community: fiduciary,
        file,
      });
      setTmp("");
      setValuesVideo(res.data.data.fullMediaUrl);
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);

      console.error(error);
    }
  };
  // const valuesList = portals.public.config?.values?.list ?? [];
  // const valuesVideo = portals.public.config?.values?.video ?? "";
  // IconUploadSpinner
  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      <div className={styles.section}>
        <div className={styles.top}>
          <div className={styles.name}>{_("values")}</div>
          {/* <Switch
                isChecked={
                  portals.public.config.about.aboutPage.values.activated
                }
                onChange={(e) => handleAboutPageBlocChange(e, "values")}
              /> */}
        </div>

        {/* {portals.public.config.about.aboutPage.values.activated && ( */}
        <Tabs theme="wtheme">
          {languages.map((language) => (
            <div
              key={`langtab${language.value}`}
              label={language.label}
              icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
            >
              <div className={styles.langTab}>
                <label className={styles.configLabel}>
                  {_(`title_${language.value}`)}
                </label>

                <input
                  className={styles.formInput}
                  value={
                    portals.public.config?.values?.[titleAttr(language.value)]
                  }
                  onChange={(e) =>
                    handlePortalBlocTitleChange(
                      language.value,
                      e.target.value,
                      "values"
                    )
                  }
                />

                <label
                  className={`${styles.configLabel} ${styles.descriptionLabel}`}
                >
                  {_(`description_${language.value}`)}
                </label>
                <textarea
                  rows="4"
                  className={styles.descriptionInput}
                  value={
                    portals.public.config?.values?.[
                      descriptionAttr(language.value)
                    ]
                  }
                  onChange={(e) =>
                    handlePortalBlocDescriptionChange(
                      language.value,
                      e.target.value,
                      "values"
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Tabs>
        {valuesList.length < 4 && (
          <Button
            className={styles.addMedia}
            size={"sm"}
            onClick={() =>
              setValuesList([
                ...valuesList,
                {
                  id: uuidv4(),
                  title: "",
                  description: "",
                },
              ])
            }
          >
            {_("add_new_value")}
          </Button>
        )}

        {valuesList.length > 0 &&
          valuesList.map((item) => (
            <div key={item.id} className={styles.numberForm}>
              <input
                className={classNames(styles.formInput, styles.title)}
                placeholder={_("title")}
                value={item.title}
                onChange={(e) => {
                  setValuesList(
                    valuesList.map((el) => {
                      if (el.id === item.id) {
                        return {
                          ...el,
                          title: e.target.value,
                        };
                      }
                      return el;
                    })
                  );
                }}
              />
              <input
                className={classNames(styles.formInput, styles.description)}
                placeholder={_("description")}
                value={item.description}
                onChange={(e) => {
                  setValuesList(
                    valuesList.map((el) => {
                      if (el.id === item.id) {
                        return {
                          ...el,
                          description: e.target.value,
                        };
                      }
                      return el;
                    })
                  );
                }}
              />
              <button
                onClick={() => {
                  setValuesList(valuesList.filter((el) => el.id !== item.id));
                }}
              >
                <IconTrash />
              </button>
            </div>
          ))}
        <div className={styles.video}>
          <input
            type="file"
            accept="video/*"
            ref={fileInput}
            style={{ display: "none" }}
            onChange={(e) => {
              const selectedFile = e.target.files[0];

              if (selectedFile) {
                if (selectedFile.size > TTP_MAX_FILE_SIZE) {
                  setError(
                    _("upload_max_file_size").replace(
                      "%maxFileSize%",
                      TTP_MAX_FILE_SIZE_TEXT
                    )
                  );
                } else {
                  setError("");
                  setTmp(e.target.value);
                  uploadFile(e.target.files[0]);
                }
              }
            }}
            value={tmp}
          />
          <div className={styles.flex}>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Button
              className={styles.addMedia}
              size={"sm"}
              onClick={() => fileInput?.current?.click()}
              disabled={isUploading}
            >
              {_(isUploading ? "uploading" : "add_video")}
            </Button>
            {valuesVideo && (
              <Button
                className={styles.addMedia}
                size={"sm"}
                variant={"danger"}
                onClick={() => setValuesVideo(null)}
              >
                {_("remove_video")}
              </Button>
            )}
          </div>

          {valuesVideo && (
            <video
              className={styles.video_preview}
              src={valuesVideo}
              controls
            ></video>
          )}
        </div>
      </div>
    </div>
  );
};

export const GalleryAppPublic = ({
  editSection,
  handlePortalBlocChange,
  portals,
  aboutImage,
  setAboutImage,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  handleAboutPageBlocChange,
  handleAboutPageBlocTitleChange,
  handleAboutPageBlocDescriptionChange,
  valuesList,
  setValuesList,
  valuesVideo,
  setValuesVideo,
  galleryMedias,
  setGalleryMedias,
  showPageConfig = false,
  inModal,
}) => {
  const fileInput = useRef();
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [addMediaModalOpened, setAddMediaModalOpened] = useState(false);
  const [galleryModalOpened, setGalleryModalOpened] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [tmp, setTmp] = useState("");

  // const uploadFile = async (file) => {
  //   setIsUploading(true);
  //   try {
  //     const res = await saveMedia({
  //       token: auth.token,
  //       community: fiduciary,
  //       file,
  //     });
  //     setTmp("");
  //     setValuesVideo(res.data.data.fullMediaUrl);
  //     setIsUploading(false);
  //   } catch (error) {
  //     setIsUploading(false);

  //     console.error(error);
  //   }
  // };

  // IconUploadSpinner
  return (
    <div
      className={classNames(styles.portalBloc, inModal ? styles.inModal : "")}
    >
      {!inModal && (
        <div className={styles.portalBloc_top}>
          <div className={styles.portalBloc_top_left}>
            <label>{_("about")}</label>
          </div>
        </div>
      )}

      <div className={styles.section}>
        <div className={styles.top}>
          <div className={styles.name}>{_("gallery")}</div>
        </div>
        {/* {portals.public.config.about.aboutPage.gallery.activated && ( */}
        <>
          <Tabs theme="wtheme">
            {languages.map((language) => (
              <div
                key={`langtab${language.value}`}
                label={language.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
              >
                <div className={styles.langTab}>
                  <label className={styles.configLabel}>
                    {_(`title_${language.value}`)}
                  </label>

                  <input
                    className={styles.formInput}
                    value={
                      portals.public.config?.gallery?.[
                        titleAttr(language.value)
                      ]
                    }
                    onChange={(e) =>
                      handlePortalBlocTitleChange(
                        language.value,
                        e.target.value,
                        "gallery"
                      )
                    }
                  />

                  <label
                    className={`${styles.configLabel} ${styles.descriptionLabel}`}
                  >
                    {_(`description_${language.value}`)}
                  </label>
                  <textarea
                    rows="4"
                    className={styles.descriptionInput}
                    value={
                      portals.public.config?.gallery?.[
                        descriptionAttr(language.value)
                      ]
                    }
                    onChange={(e) =>
                      handlePortalBlocDescriptionChange(
                        language.value,
                        e.target.value,
                        "gallery"
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </Tabs>
          {galleryMedias.length < 7 && (
            <Button
              className={styles.addMedia}
              size={"sm"}
              onClick={() => setGalleryModalOpened(true)}
            >
              {_("add_media")}
            </Button>
          )}
          <MediaList
            medias={galleryMedias}
            setMedias={setGalleryMedias}
            language={lng}
            isUsed={true}
          />
          <AddMedia
            isOpen={galleryModalOpened}
            close={() => {
              setGalleryModalOpened(false);
            }}
            token={auth.token}
            community={fiduciary}
            medias={galleryMedias}
            setMedias={(val) =>
              setGalleryMedias([
                // ...galleryMedias,
                ...val.slice(0, 7 - galleryMedias.length),
              ])
            }
          />
        </>
        {/* )} */}
      </div>
    </div>
  );
};
