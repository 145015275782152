import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

import { COUNTRIES, LEGAL_FORM, TVA_TYPE, LANGUAGES } from "../../config";
import SECTORS from "../../config/sectors";
import _ from "../../i18n";
import { saveFiduciary } from "../../api";

import Loader from "../common/Loader";
import Button from "../common/Button";
import Dropzone from "../common/Dropzone";
import FormInput from "../common/FormInput";
import FormSelect from "../common/FormSelect";
import styles from "./FiduciaryModal.module.scss";

const FiduciaryModal = ({
  modalOpen,
  setModalOpen,
  fiduciary,
  refreshList,
  contactedOptions,
}) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [files, setFiles] = useState([]);
  const [id, setId] = useState(null);
  const [logo, setLogo] = useState("");
  const [contactId, setContactId] = useState("");
  const [contactEmailId, setContactEmailId] = useState("");
  const [uen, setUen] = useState("");
  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [url, setUrl] = useState("");
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState({ value: "BE", label: COUNTRIES.BE });
  const [contactedStatus, setContactedStatus] = useState(null);
  // const [legalForm, setLegalForm] = useState({
  //   value: "SRL",
  //   label: LEGAL_FORM.SRL,
  // });
  // const [tva, setTva] = useState({
  //   value: "VAT_SYSTEM_VAT",
  //   label: TVA_TYPE.VAT_SYSTEM_VAT,
  // });
  // const [languagePreferences, setLanguagePreferences] = useState([]);
  // const [sector, setSector] = useState({
  //   value: 81,
  //   label: SECTORS.filter((s) => s.id === 81)[0].title,
  // });

  const [isValidForm, setIsValidForm] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const [uenError, setUenError] = useState("");
  const [nameError, setNameError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [languagePreferencesError, setLanguagePreferencesError] = useState("");
  const [isDisabed, setIsDisabed] = useState(false);

  useEffect(() => {
    if (fiduciary) {
      setId(fiduciary.id);
      // setContactId(fiduciary.allLegalRepresentative[0].id);
      // setContactEmailId(fiduciary.allLegalRepresentative[0].email[0].id);
      setUen(fiduciary.uen);
      setName(fiduciary.name);
      setAbbreviation(fiduciary.abbreviation ? fiduciary.abbreviation : "");
      if (fiduciary.website) setUrl(fiduciary.website);
      setAddress1(fiduciary.address1);
      setZipCode(fiduciary.zipCode);
      setCity(fiduciary.city);
      setCountry({
        value: fiduciary.country,
        label: COUNTRIES[fiduciary.country],
      });
      setIsAccepted(true);
      setLogo(fiduciary.avatarUrl);
      const contactValue = contactedOptions.filter(
        (i) => i.value === fiduciary.contactedStatus
      );
      setContactedStatus(contactValue[0]);
    }
  }, [fiduciary]);

  const handleChange = (e) => {
    let name = e.target.name;
    eval("set" + name.charAt(0).toUpperCase() + name.slice(1))(e.target.value);
    handleValidation();
  };

  const handleNameChange = (e) => {
    let message = e.target.value.length === 0 ? _("required_field") : "";
    setNameError(message);
    setName(e.target.value);
    handleValidation();
  };

  const handleAddressChange = (address) => {
    let message = address.length === 0 ? _("required_field") : "";
    setAddress1Error(message);
    setAddress1(address);
    handleValidation();
  };

  const handleSelect = (address) => {
    setAddress1(address);
    geocodeByAddress(address)
      .then((results) => results[0].address_components)
      .then((addressComponents) => {
        const country = addressComponents.filter((address) =>
          address.types.includes("country")
        );
        const zipCode = addressComponents.filter((address) =>
          address.types.includes("postal_code")
        );
        const city = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("locality")
        );
        if (city.length > 0) setCity(city[0].long_name);
        if (zipCode.length > 0) setZipCode(zipCode[0].long_name);
        if (country.length > 0 && COUNTRIES[country[0].short_name])
          setCountry({
            value: country[0].short_name,
            label: COUNTRIES[country[0].short_name],
          });
      })
      .catch((error) => console.error("Error", error));
  };

  const requiredField = (e) => {
    let name = e.target.name;
    let message = e.target.value.length === 0 ? _("required_field") : "";
    eval("set" + name.charAt(0).toUpperCase() + name.slice(1) + "Error")(
      message
    );
  };

  const handleValidation = () => {
    let isValid = true;
    const formattedUen = uen.replace(
      /(BE|be)? ?(\d{4})[. ]?(\d{3})[. ]?(\d{3})/,
      "BE $2.$3.$4"
    );
    if (
      !isAccepted ||
      tva === "" ||
      uen === "" ||
      name === "" ||
      zipCode === "" ||
      country === "" ||
      address1 === "" ||
      languagePreferences.length === 0 ||
      !/^[a-zA-Z]{2} (\d{4})[. ]?(\d{3})[. ]?(\d{3})$/.test(formattedUen)
    ) {
      isValid = false;
    }

    setIsValidForm(isValid);
  };

  const handleSave = async () => {
    setIsSaving(true);
    saveFiduciary({
      token: auth.token,
      files,
      id,
      uen,
      name,
      abbreviation: abbreviation,
      address1,
      zipCode,
      city,
      url,
      country: country ? country.value : "",
      contactedStatus: contactedStatus ? contactedStatus.value : "CONTACTED",
    })
      .then((resp) => {
        setIsSaving(false);
        if (resp.data.result === "OK") {
          refreshList();
          toast.success(_("successfully_added"));
          setModalOpen(false);
        }
      })
      .catch((e) => {
        setIsSaving(false);
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Modal
      width="70vw"
      height="50vh"
      closable={false}
      visible={modalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        setModalOpen(false);
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>{_("company_data")}</div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          setModalOpen(false);
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>

      <div className={styles.content}>
        <div className="grid-x grid-margin-x">
          <div className="d-flex-col cell large-3">
            <label className="ttp-label">{_("company_logo")}</label>
            <Dropzone
              files={files}
              setFiles={setFiles}
              defaultImg={logo}
              hasBorder
            />
          </div>
          <div className="cell small-12 large-9 grid-x grid-margin-x">
            <div className="cell small-12 large-4">
              <FormInput
                name="uen"
                required={true}
                disbaled={true}
                placeholder="BE XXXX.XXX.XXX"
                label={_("business_num")}
                info={_("uen_info")}
                error={uenError}
                value={uen}
              ></FormInput>
            </div>

            <div className="cell small-12 large-4">
              <FormInput
                name="name"
                value={name}
                required={true}
                label={_("Nom d’entreprise")}
                error={nameError}
                autoComplete="none"
                disabled={isDisabed}
                handleBlur={(e) => requiredField(e)}
                onChange={(e) => handleNameChange(e)}
              ></FormInput>
            </div>

            <div className="cell small-12 large-4">
              <FormInput
                name="abbreviation"
                value={abbreviation}
                label={_("Abréviation")}
                onChange={(e) => handleChange(e)}
                autoComplete="none"
              ></FormInput>
            </div>
            <div className="cell large-6">
              <FormInput
                name="url"
                value={url}
                label={_("Lien URL")}
                autoComplete="none"
                onChange={(e) => handleChange(e)}
              ></FormInput>
            </div>
            <div className="cell large-6">
              <FormSelect
                value={contactedStatus}
                label="Contacté ?"
                handleChange={(option) => setContactedStatus(option)}
                options={contactedOptions}
              />
            </div>
          </div>
        </div>

        <hr className={styles.separator} />

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 large-6">
            <label className="ttp-label">
              {_("Adresse")}
              <span className="star">*</span>
            </label>
            <PlacesAutocomplete
              value={address1}
              onChange={handleAddressChange}
              onSelect={handleSelect}
              searchOptions={{
                componentRestrictions: { country: ["be"] },
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="group-input">
                  <input
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className: "location-search-input",
                    })}
                    autoComplete="none"
                    name="address1"
                    disabled={isDisabed}
                    onBlur={(e) => {
                      requiredField(e);
                    }}
                  />
                  <span className="ttp-error">{address1Error}</span>
                  {suggestions.length > 0 ? (
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="cell small-12 large-6 grid-x grid-margin-x">
            <div className="cell small-12 large-4">
              <FormInput
                name="zipCode"
                value={zipCode}
                label={_("Code postal")}
                required={true}
                error={zipCodeError}
                autoComplete="none"
                disabled={isDisabed}
                handleBlur={(e) => requiredField(e)}
                onChange={(e) => handleChange(e)}
              ></FormInput>
            </div>
            <div className="cell small-12 large-4">
              <FormInput
                name="city"
                value={city}
                label={_("City")}
                required={true}
                error={cityError}
                autoComplete="none"
                disabled={isDisabed}
                handleBlur={(e) => requiredField(e)}
                onChange={(e) => handleChange(e)}
              ></FormInput>
            </div>
            <div className="cell small-12 large-4">
              <FormSelect
                name="country"
                value={country}
                label={_("Country")}
                required={true}
                disabled={isDisabed}
                handleChange={(option) => setCountry(option)}
                options={Object.entries(COUNTRIES).map(([k, v]) => {
                  return { value: k, label: v };
                })}
              />
            </div>
          </div>
        </div>

        {/* <hr className={styles.separator} />

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 large-3">
            <FormSelect
              name="legalForm"
              value={legalForm}
              label={_("Forme Légal")}
              required={true}
              handleChange={(option) => setLegalForm(option)}
              options={Object.entries(LEGAL_FORM).map(([k, v]) => {
                return { value: k, label: v };
              })}
            />
          </div>
          <div className="cell small-12 large-3">
            <FormSelect
              name="sector"
              value={sector}
              label={_("Secteur d'activité")}
              handleChange={(option) => setSector(option)}
              options={SECTORS.map((sect) => {
                return { value: sect.id, label: sect.title };
              })}
            />
          </div>
          <div className="cell small-12 large-3">
            <FormSelect
              name="tva"
              value={tva}
              label={_("Type de TVA")}
              required={true}
              handleChange={(option) => setTva(option)}
              options={Object.entries(TVA_TYPE).map(([k, v]) => {
                return { value: k, label: v };
              })}
            />
          </div>
          <div className="cell small-12 large-3">
            <FormSelect
              isMulti
              name="languagePreferences"
              value={languagePreferences}
              label={_("Préférences de langues")}
              required={true}
              options={LANGUAGES}
              error={languagePreferencesError}
              handleBlur={handleLanguageBlur}
              handleChange={handleLanguageChange}
            />
          </div>
        </div> */}
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={() => setModalOpen(false)}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={handleSave}>{_("save")}</Button>
        )}
      </div>
    </Modal>
  );
};

export default FiduciaryModal;
