const faqFr = {
  continue: "Continuer",
  step_company_details: "Données d'entreprise",
  step_collaborator: "Collaborateurs",
  step_clients: "Clients",
  step_contacts: "Autres contacts",
  step_students: "Etudiants",
  add_collaborator_title: "Ajoutez les collaborateurs",
  add_student_title: "Ajoutez les étudiants",
  express: "Express",
  manual: "Manuelle",
  import_xls_file: "Importer (.xls)",
  email_address: "Adresse E-mail",
  email: "E-mail",
  firstname: "Prénom",
  lastname: "Nom",
  add: "Ajouter",
  cancel: "Annuler",
  english: "Anglais",
  french: "Français",
  dutch: "Néerlandais",
  en: "Anglais",
  fr: "Français",
  nl: "Néerlandais",
  phone_number: "Téléphone",
  collaborator_role: "Rôle collaborateur",
  language: "Langue",
  LEGAL_REPRESENTATIVE: "Représentant légal",
  MANAGER: "Gérant",
  OFFICIAL: "Collaborateur",
  EXTERNAL: "Externe",
  ADMINISTRATOR: "Administrateur",
  validate_email: "Veuillez fournir une adresse électronique valide",
  required_field: "Ce champ est obligatoire",
  required_2_characters: "Veuillez fournir au moins 2 caractères",
  collaborators_list: "Liste collaborateurs",
  import_collaborators: "Importer des employés",
  import_contacts: "Importer des contacts",
  edit: "Modifier",
  delete: "Supprimer",
  delete_confirm: "Confirmation de suppression",
  text_delete_collaborator:
    "Êtes-vous sûr de bien vouloir supprimer cet élément ?",
  no: "Non",
  yes: "Oui",
  uen_info:
    "Numéro unique d'entreprise, aussi connu sous l'appellation KBO ou BCE. Nous utilisons le format <span>BE XXXX.XXX.XXX</span>. Cette valeur est utilisée pour identifier les entreprises de manière unique sur notre plateforme.",
  validate_uen: "Veuillez fournir un numéro valide",
  add_customer_records_title: "Ajouter les dossiers clients",
  client_file_list: "Liste dossiers clients",
  company_name: "Nom d'entreprise",
  business_number: "Numéro d'entreprise",
  legal_form: "Forme légale",
  client_contact: "Contact client",
  linked_contact: "Contact lié",
  add_contact_title: "Ajoutez d'autres contacts",
  "folder.uen.not.unique": "Numéro d'entreprise existe déja",
  error: "Erreur",
  contacts_list: "Liste des contacts",
  finish: "Terminer",
  gender: "Genre",
  add_confirm: "Confirmation d'ajout",
  text_add_exist_collaborator:
    "Ce collaborateur appartient à une autre organisation. Les champs seront désactivés jusqu'à l'acception de l'invitation. Êtes-vous sûr de bien vouloir l'ajouter?",
  text_popover_add_exist_collaborator:
    "Ce collaborateur appartient à une autre organisation. Êtes-vous sûr de bien vouloir l'ajouter? Sinon, vous pouvez supprimer cette ligne",
  successfully_deleted: "L'enregistrement a été supprimé avec succès",
  successfully_added: "Enregistrement a été ajouté avec succès",
  text_delete_service: "Êtes-vous sûr de bien vouloir supprimer ce service ?",
  service_title: "Titre du service",
  service_description: "Description du service",
  select_icon: "Sélectionner l'icône",
  is_private: "Est privé",
  add_service: "Ajouter un service",
  validate_service_title: "Les titres sont obligatoires",
  validate_service_title_length:
    "Les titres doivent contenir 3 caractères ou plus",
  validate_service_icon: "L'icône est obligatoire",
  something_went_wrong: "Quelque chose s'est mal passé",
  update_service: "Mise à jour du Service",
  opening_time: "Horaires",
  office_closed: "Fermé",
  all_training: "Toutes les formations",
  all_authors: "Tous les auteurs",
  no_article_found: "Aucun article trouvé",
  Purchase: "Acheter",
  welcome_to: "Bienvenue sur",
  home_description:
    "Créer votre socle numérique et rejoignez le nouveau réseau technologique des professions économiques et juridiques.",
  subscribe: "S'abonner au réseau United Associates",
  already_subscribed: "je suis déjà abonné",
  home_footer: "Le socle technologique est développé par Tamtam international.",
  validate: "Valider",
  male: "Homme",
  female: "Femme",
  mobile_phone_number: "Numéro Téléphone Mobile",
  email_validated_successfully: "Adresse email validée avec succès",
  account_exist_login:
    "Ce compte existe déjà dans l'univers Tamtam:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li><li><small>- Forum For the Future</small></li></ul>Veuillez saisir votre mot de passe",
  account_exist_pwd:
    "Ce compte existe déjà dans l'univers Tamtam:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li><li><small>- Forum For the Future</small></li></ul>Veuillez définir un mot de passe",
  password_weak: "Le mot de passe est faible!",
  password_not_match: "Veuillez entrez à nouveau la même valeur",
  password: "Mot de passe",
  confirm_password: "Confirmez le mot de passe",
  numeroAgreation: "Numéro d'agréation (professions économiques)",
  check_your_email:
    "Veuillez vérifier votre boite email pour valider votre adresse",
  already_sent_email:
    "Nous avons déjà envoyer un email de confirmation dans votre boite.",
  resend_confirmation_email: "Réenvoyer un email de confirmation",
  create_account: "Créer un compte",
  signup: "S’inscrire",
  signin: "Se connecter",
  forgot_password: "Oublié?",
  must_accept_conditions: "Vous devez accepter nos conditions d'utilisation",
  itaa: "Bureau fiduciaire ITAA",
  ire_audit: "Cabinet d’audit IRE",
  law_office: "Cabinet d’avocats",
  notarial_office: "Cabinet d’étude Notariale",
  other: "Autres...",
  validate_phone: "Le numéro de téléphone est invalide",
  title_office_step_1: "Données de votre cabinet",
  subTitle_office_step_1:
    "Les coordonnées du cabinet serviront à la facturation. La taille définit votre abonnement.",
  title_office_step_2:
    "Vous avez opté pour une adhésion pour un cabinet %1%+%2% collaborateurs",
  subTitle_office_step_2:
    "Cette adhésion vous permet de bénéficier de l’accès illimité en fonction de votre taille et à un set de contenu et d’application.",
  checkbox_person_title: "cabinet ",
  checkbox_person_subTitle: "personne physique",
  checkbox_person_description:
    "L'exercice en personne physique suppose que vous n'utilisez pas de personne morale pour exercer",
  checkbox_office_title: "cabinet ",
  checkbox_office_subTitle: "entreprise ",
  checkbox_office_description:
    "L'exercice en société suppose que vous utilisez une personne morale pour exercer.",
  checkbox_fiduciary_title: "Bureau ",
  checkbox_fiduciary_subTitle: "Fiduciaire ITAA",
  checkbox_itaa_title: "Bureau ",
  checkbox_itaa_subTitle: "Fiduciaire ITAA",
  checkbox_ire_audit_title: "Cabinet",
  checkbox_ire_audit_subTitle: "D'audit IRE",
  checkbox_law_office_title: "Cabinet",
  checkbox_law_office_subTitle: "D'avocats",
  checkbox_notarial_office_title: "Cabinet",
  checkbox_notarial_office_subTitle: "D'étude Notariale",
  checkbox_other_subTitle: "Autre ...",

  app_blog_desc:
    "Utiliser l'application Blog pour accéder gratuitement à l'actualité économique, juridique, comptable et fiscale.  Utilisez ces contenus pour votre organisation et complétez-les de vos propres contenus ou de contenus curetés.",
  app_blog_details_1_subTitle:
    "Une actualité continue en matière fiscale, comptable, sociale et économiques",
  app_blog_details_2_subTitle:
    "Une newsletter mensuelle préparée, prête à l'emploi",
  app_blog_tools_1_title: "Rédiger vos articles",
  app_blog_tools_1_desc:
    "Ajoutez une couche perso à votre actualité. Cette option vous permet de créer votre propre blog, en sus de celui du FFF et de ses chaines.",
  app_blog_tools_2_title: "Insérer des articles externes",
  app_blog_tools_2_desc:
    "Référencés des articles extérieurs qui vous parlent : c'est le curatage, disponible par cette option.",
  app_blog_tools_3_title: "Créer des Newsletters",
  app_blog_tools_3_desc:
    "Assembler une sélection d'articles à destination de votre mailing list. Communiquer par newsletter n'aura jamais été aussi facile.",

  app_faq_desc:
    "Accéder à une bibliothèque de questions-réponses les plus populaires.  Utilisez ces contenus pour votre organisation et créez un centre d'assistance pour vos clients.",
  app_faq_details_1_title: "Toutes les questions/réponses du secteur",
  app_faq_details_1_subTitle:
    "Inclus dans votre abonnement les FAQ FFF et ses   chaines",
  app_faq_details_2_title:
    "Transformez FAQ en un outil propre de communication",
  app_faq_details_2_subTitle: "2 sur 2 outils inclu dans votre abonnement",
  app_faq_tools_1_title: "Créer vos FAQ",
  app_faq_tools_1_desc:
    "Ajoutez une couche perso à votre FAQ. Cette option vous permet de créer vos propres FAQ, en sus de celles du FFF et de ses chaines.",
  app_faq_tools_2_title: "Créer votre centre d'assistance",
  app_faq_tools_2_desc:
    "Incorporer les connaissances répertoriées dans les FAQ pour fournir à vos clients un centre d'assistance sous un format « suivez le guide ».",
  app_webinar_desc:
    "Accéder à une bibliothèque de formation on line pour tout le cabinet en vous basant sur la bibliothèque oFFFcourse et celle de ses chaines. Selon votre abonnement vous disposez d'un ou plusieurs accès premium (avec attestation) et un partage illimité. Complétez ensuite les formations disponibles de vos propres formations.",
  app_webinar_details_1_title:
    "Toutes les formations du secteur : un levier d'éducation",
  app_webinar_details_2_title: "Partager mon Premium",
  app_webinar_details_2_subTitle:
    "Cette option vous permet de partager sans attestation avec 4 membres de votre cabinet un abonnement premium, 8 membres 2 abonnement premium, etc…",
  app_webinar_tools_1_title: "Partager mon premium",
  app_webinar_tools_1_desc:
    "Cette option vous permet de partager avec tous les membres de votre cabinet votre abonnement premium.",
  app_webinar_tools_2_title: "Offrir un premium à tous",
  app_webinar_tools_2_desc:
    "Cette option vous permet de partager la bibliothèque avec tous les membres du cabinet tout en gérant leurs programmes et leurs attestations.",
  app_webinar_tools_3_title: "Créer mes propres webinars",
  app_webinar_tools_3_desc:
    "Cette option vous permet de créer vos propres webinars et de les partager avec vos collaborateurs.",
  app_webinar_tools_4_title: "Partager les webinars avec mes clients",
  app_webinar_tools_4_desc:
    "Cette option vous permet de partager des webinars avec vos clients au travers du portail client.",
  app_webtools_desc:
    "Accéder à une bibliothèque de webtools les plus populaires.  Utilisez ces contenus pour votre organisation et créez un tool center pour vos clients.",
  app_webtools_details_1_title: "Tous les webtools du secteur",
  app_webtools_details_1_subTitle:
    "Inclus dans votre abonnement les Webtools FFF",
  app_webtools_details_2_title:
    "Transformez webtools en une bibliothèque de ressources pour vos clients",
  app_webtools_details_2_subTitle:
    "2 sur 3 outils inclus dans votre abonnement",
  app_webtools_tools_1_title: "Créer vos webtools",
  app_webtools_tools_1_desc:
    "Ajoutez une couche perso à votre webtools. Cette option vous permet de créer vos propres webtools, en sus de celles du FFF et de ses chaines.",
  app_webtools_tools_2_title: "Créer votre tool center",
  app_webtools_tools_2_desc:
    "Incorporer les outils répertoriés dans les webtools pour fournir à vos clients un centre d'assistance sous un format « suivez le guide ».",
  app_event_desc:
    "Accéder à une bibliothèque de formation présentielle pour tout le cabinet en vous basant sur la bibliothèque des évents oFFFcourse et celle de ses chaines. Complétez ensuite le catalogue de ces évents en ajoutant vos propres évents",
  app_event_details_1_title: "Tous les événements du secteur",
  app_event_details_1_subTitle:
    "Seules les formations gratuites de oFFFcourse et ses chaines sont incluses dans votre abonnement",
  app_event_details_2_title:
    "Organisez vos propres formations pour vos clients et collaborateurs",
  app_event_details_2_subTitle: "2 sur 3 outils inclus dans votre abonnement",

  app_event_tools_1_title: "Créer mes propres évents gratuits",
  app_event_tools_1_desc:
    "Cette option vous permet de créer vos propres évents gratuits et de gérer les invitations et son organisation.",
  app_event_tools_2_title: "Créer mes propres évents payants",
  app_event_tools_2_desc:
    "Cette option vous permet de créer vos propres évents payants et de gérer les invitations et son organisation.",

  app_accounting_desc:
    "Organiser la présentation de vos travaux annuels après de vos clients en utilisant le nouvel outil de présentation en format slides show (Dashboard). Complétez ensuite la présentation de format personnalisé.",
  app_accounting_details_1_title:
    "Tous vos clients bénéficient d'un reporting professionnel",
  app_accounting_details_2_title:
    "Transformez vos travaux en une opération pédagogique et communicationnelle",
  app_accounting_details_2_subTitle:
    "2 sur 3 outils inclus dans votre abonnement",

  app_accounting_tools_1_title:
    "Etendez votre présentation par l'utilisation d'une batterie de vue et de ratio",
  app_accounting_tools_1_desc:
    "Cette option vous permet d'ajouter à votre slides show une vue des comptes annuels sur plusieurs années, avec diverses analyses et comparaisons.",
  app_accounting_tools_2_title:
    "Partagez la présentation sur le portail client",
  app_accounting_tools_2_desc:
    "Cette option vous permet de partager votre slides show, les analyses et les comparaisons à votre client sur son portail personnel.",
  app_accounting_tools_3_title: "Générer vos propres standards de présentation",
  app_accounting_tools_3_desc:
    "Cette option vous permet de créer de personnaliser vos présentation selon vos besoins et ceux de vos clients.",

  version_beta: "Version Bêta",
  until: "Jusqu'au",
  business_num: "Numéro d'entreprise",
  approval_num: "Numéro d'agréation",
  abbreviation: "Abréviation",
  url_link: "Lien URL",
  address: "Adresse",
  activity_area: "Secteur d'activité",
  my_portals: "Mes Portails",
  installed_app: "Installées",
  future_app: "À Venir",
  option_app: "En Option",
  customer_records: "Clients",
  other_contacts: "Contacts",
  other_contact: "Autres contacts",
  more_than: "plus de",
  of: "de",
  close: "Fermer",
  step_payment: "Paiement",
  waiting_for_payment: "La commande est en attente de paiement",
  waiting_for_payment_subtitle:
    "Le bon de commande vous a été envoyé par email. Pour rendre votre adhésion plus rapide, procéder directement à votre paiement en privilégiant les paiements en ligne.",
  successful_payment: "Votre paiement a été effectué avec succès",
  command_details: "Détails de la commande",
  facture_details: "Détails de la facture",
  membership_to_ua_for: "Adhésion au réseau United Associates pour",
  membership_ends_in: "Cette adhésion prend fin au",
  description: "Description",
  price: "Prix",
  quantity: "Quantité",
  tva_rate: "Taux de TVA",
  tvac_amount: "Montant TVAC",
  membership_cabinet_of: "Adhésion pour un cabinet de",
  to: "à",
  collaborator: "Collaborateur",
  collaborators: "Collaborateurs",
  client: "Client",
  clients: "Clients",
  download_command: "Télécharger la commande",
  download_invoice: "Télécharger la facture",
  pay_here: "Payer ici",
  choose_payment_method: "Choisissez votre méthode de paiement",
  code_will_expire: "Le code QR de Payconiq sera expiré",
  in: "dans",
  online_payment: "Paiement en ligne",
  selected: "Choisi",
  choose: "Choisir",
  bank_payment: "Virement bancaire",
  use_this_card: "Payer avec cette carte",
  new_card: "Nouvelle carte bancaire",
  card_number: "Numéro de la carte",
  expiration_date: "Date d’expiration",
  remember_card: "Enregistrer ma carte",
  payment_denied: "Paiement refusé",
  payment_denied_p:
    "Nous regrettons de ne pouvoir donner suite favorable à votre demande de paiement.",
  retry_payment: "réessayer le paiement",
  summary: "Récapitulatif",
  subscription_choice: "choix d'abonnement",
  joining_ua_for: "Adhésion au réseau United associates pour",
  number_employees: "Nombre de collaborateurs",
  between: "Entre",
  and: "et",
  company_data: "Données d’entreprise",
  payment: "paiment",
  payment_date: "Date de paiement",
  payment_mode: "Mode de paiement",
  amount: "Montant",
  status: "Statut",
  payment_made: "Paiement effectué",
  subscribed_to_ua: "Vous êtes abonnés à United Associates",
  back: "Retour",
  my_profile: "Mon profil",
  add_new_email: "Ajouter une adresse e-mail",
  edit_license_number: "Modifier le numéro d’agéation",
  set_as_principal: "Définir cette adresse en tant que principale",
  edit_license_number_p:
    "Le numéro d’agrément est utile pour nous permettre de vous donner certains accès. Ce numéro est aussi indispensable dans le cadre des formations agréées pour la délivrance des attestations.",
  ITAA_trust_office: "Bureau fiduciaire ITAA",
  IRE_audit_firm: "Cabinet d’audit IRE",
  law_office: "Cabinet d’avocats",
  notarial_office: "Cabinet d’étude Notariale",
  other: "Autre",
  manage_information: "Gérez vos informations personelles",
  photo: "Photo",
  photo_p: "Personnalisez votre compte en ajoutant une photo",
  first_last_name: "Nom / Prénom",
  principale: "Principale",
  non_principale: "Non pricipale",
  principale_email_p:
    "Votre adresse principale est l’adresse que le système utilise pour vous contacter et pour vous communiquer des informations. L’usage de plusieurs adresses email vous permet de vous connecter, quelle que soit l'adresse email utilisée pour ce même profil (compte).",
  password: "Mot de passe",
  medium_password: "Le mot de passe est Moyen!",
  license_number: "Numéro d’agréation (professions économiques)",
  phone_number_1: "Numéro de téléphone",
  office: "Bureau",
  phone_number_1_p:
    "  Votre numéro de téléphone principal doit être idéalement un numéro de portable. Il permettra d’améliorer la sécurité moyennant une identification à double facteur.",
  add_new_phone_number: "Ajouter un numéro de téléphone",
  birth_date: "Date de naissance",
  gender_1: "Sexe",
  reject: "Rejeter",
  put_on_pending: "Mettre en attente",
  ua_status_successfully: "Le statut a été modifié avec succès",
  ua_validate_confirm: "Confirmer le changement",
  text_ua_validate_confirm: "Voulez vous valider la fiduciaire?",
  text_ua_put_on_pending_confirm:
    "Voulez vous mettre en attente la fiduciaire?",
  text_ua_reject_confirm: "Voulez vous refuser la fiduciaire?",
  save: "Enregistrer",
  empty_list: "Liste vide",
  all: "Tous",
  validated: "Validé",
  pending: "En attente",
  rejected: "Rejeté",
  accepted: "Accepté",
  created: "Créé",
  reminder: "Relancer",
  change_password: "Changer le mot de passe",
  reminder_email_sent_successfully:
    "L'email de rappel a été envoyé avec sucess",
  reset_password: "Définir le mot de passe",
  user_added: "utilisateur(s) ajouté(s)",
  user_added_with_error:
    "utilisateur(s) ajouté(s). Vérifier les adresses restantes",
  import_clients: "Importer des clients",
  CHIEF_EDITOR: "Rédacteur en chef",
  AUTHOR: "Auteur",
  AUTHOR_MANDATED: "Auteur mandaté",
  REDACTOR: "Rédacteur",
  REDACTOR_MANDATED: "Rédacteur mandaté",
  modal_avatar_title: "Personnaliser l'image",
  save: "Enregistrer",
  change_image: "Changer l'image",
  zoom: "Zoomer",
  rotation: "Rotation",
  edit_name: "Modifier le prénom et le nom",
  old_password: "Ancien mot de passe",
  set_as_principal_phone: "Définissez ce numéro comme principal",
  edit_birthday: "Modifier l'anniversaire",
  birthday: "Anniversaire",
  saving_changes: "Enregistrement des modifications...",
  edit_gender: "Modifier le sexe",
  edit_language: "Modifier la langue",
  uen_label_number: "Saisissez le numéro",
  oldPassword_incorrect: "Ancien mot de passe incorrect",
  email_not_unique: "Adresse e-mail déjà prise",
  add_media: "Ajouter un nouveau média",
  in_progress: "En cours",
  send: "Envoyer",
  password_changed_succesfully: "Mot de passe enregistré avec succés",
  reset_pwd_check_your_email:
    "Un message a été envoyé à votre boîte e-mail. Veuillez le consulter et suivre les instructions.",
  successfully_saved: "Enregistré avec succès",
  title_exist_fiduciary_confirm: "Confirmation",
  text_exist_fiduciary:
    "Cette fiduciaire existe dans la base de données de Tamtam. Etes-vous sûr de vouloir continuer?",
  search: "Recherche ...",
  save_confirm: "Confirmation d'enregistrement",
  text_save_group:
    "Voulez vous ajouter les contacts sélectionnés à ce groupe ?",
  text_delete_group: "Voulez vous supprimer le contact de ce groupe ?",
  group_filter: "Filtrer les groupes",
  groups: "Groupes",
  group_exclude: "Exclure les groupes",
  search_group: "Rechercher un groupe",
  add_from_biblio: "Ajouter de la bibliothèque",
  biblio_services: "Bibliothèque des services",
  confirm: "Confirmer",
  empty_biblio: "La bibliothèque est vide ",
  category: "Catégorie",
  author_type: "Type d'auteurs",
  accountant: "Expert-comptable",
  tax_advice: "Conseil fiscal",
  lawyer: "Avocat",
  notary: "Notaire",
  consultant: "Consultant",
  professor: "Professeur",
  journalist: "Journaliste",
  others: "Autre",
  article_feed: "Flux d’articles",
  both: "Tous (les deux)",
  my_articles: "Publications de mon cabinet",
  fff_articles: "Actualités FFF",
  relevance: "Pertinence",
  city: "Ville",
  zipCode: "Code postal",
  company_logo: "Logo d'entreprise",
  help_center_app: "Centre d'aide",
  dashboard_app: "Dashboard",
  powerteam_app: "Powerteam",
  watch_app: "Watch",
  carousel: "Carrousel",
  terms_of_use: "Conditions d'utilisation",
  manage_cookies: "Gérer les cookies",
  invalid_value: "Valeur invalide",
  existing_medias: "Bibliothèque des medias",
  split: "Diviser",
  merge: "Fusionner",
  fill_with_default_values: "Remplir avec la valeur par défaut",
  validate_data: "Veuillez vérifier les données",
  select_header: "Sélectionnez l'en-tête",
  map_fields: "Mapper les champs",
  verify_finalize: "Vérifier et finaliser",
  services_biblio: "Bibliothèque des services",
  manage_public_services: "Gérer ici les services publics",
  add_new_service: "Ajouter un nouveau service",
  text_popover_add_exist_client:
    "Ce client appartient à une autre organisation. Êtes-vous sûr de bien vouloir l'ajouter? Sinon, vous pouvez supprimer cette ligne",
  country_code: "Code pays",
  text_add_exist_client:
    "Ce client appartient à une autre organisation. Êtes-vous sûr de bien vouloir l'ajouter?",
  webtools_app: "Webtool",
  our_fields: "Nos champs",
  columns_in_file: "Colonnes dans votre fichier",
  preview: "Aperçu",
  map_columns: "Mapper les colonnes",
  reduce: "Réduire",
  show_tools: "Afficher les outils associés",
  SKILLS: "Competences",
  TECHNOLOGIES: "Technologies",
  CLIENTELE: "Clientele",
  ZONE: "Zone",
  BOOKKEEPING: "Tenue de la comptabilité",
  ADJUSTMENT_OF_THE_ANNUAL_ACCOUNTS: "Redressement des comptes annuels",
  PERIODIC_SITUATION: "Situation périodique",
  FINANCIAL_PLAN: "Plan financier",
  BUSINESS_VALUATION: "Evaluation d'entreprises",
  UBO: "UBO",
  PAYROLL_MANAGEMENT_EMPLOYEE: "Gestion de la paie (salarié)",
  PAYROLL_MANAGEMENT_ADMINISTRATOR: "Gestion de la paie (Administrateur)",
  MANAGEMENT_ALTERNATIVE_REMUNERATION: "Gestion des rémunérations alternatives",
  PLAN_CAFETERIA: "Plan cafetaria",
  SPLIT_SALARY: "Split salary",
  IMPATRIATES: "Impatriés",
  BUSINESS_CREATION: "Création d'entreprise",
  CORPORATE_REORGANIZATION: "Réorganisation d'entreprise",
  RECEIVERSHIP_PROCEEDINGS: "Procédure de redressement judiciaire",
  BUSINESS_LIQUIDATION: "Liquidation d'entreprise",
  TRANSFER_BUSINESS: "Cession d'enpreprise",
  FAMILY_OFFICE: "Family office",
  TAX_RETURNS_IPP: "Déclarations fiscales à l'IPP",
  TAX_RETURNS_ISOC: "Déclarations fiscales à l'ISOC",
  TAX_DECLARATIONS_INR: "Déclarations fiscales à l'INR",
  TAX_DECLARATIONS_IPM: "Déclarations fiscales à l'IPM",
  VAT_RETURN: "Déclaration TVA",
  TAX_REPRESENTATION: "Représentation fiscale",
  ADMINISTRATIVE_LITIGATION: "Contentieux administratifs",
  LEGAL_LITIGATION: "Contentieux judiciaires",
  RULING: "Ruling (décisions anticipées)",
  WEALTH_PLANNING: "Planification patrimoniale",
  GDPR_TIP: "Conseil GDPR",
  STRATEGIC_CONSULTING: "Conseil stratégique",
  DIGITAL_STRATEGY: "Stratégie digital",
  LBO: "LBO",
  M_A: "M&A",
  DRAFTING_STATUTES: "Rédaction des statuts",
  DRAFTING_AGREEMENTS: "Rédaction de conventions",
  AUDIT_ACCOUNTS: "Audit des comptes",
  ENERGY_AUDIT: "Audit énergétique",
  RSE: "RSE",
  HORUS: "Horus",
  XPERT_M: "Xpert-M",
  WINBOOKS: "Winbooks",
  EXACT_ONLINE: "Exact online",
  POPSY_ALLEGRO: "Popsy - Allegro",
  YUKI: "Yuki",
  BOB_SOFTWARE: "Bob software",
  OCTOPUS: "Octopus",
  ODOO: "odoo",
  BILLTOBOX: "BillToBox",
  BILLIT: "BillIt",
  FALCON: "Falco",
  WINBOOKS_CONNECT: "Winbooks connect",
  LIBERAL_PROFESIONAL: "Profesionnel libéral",
  INDIVIDUALS: "Personnes physiques",
  SMALL_COMPANIES: "Petites sociétés",
  MEDIUM_SIZED_COMPANIES: "Moyennes sociétés",
  LARGE_COMPANIES: "Grandes sociétés",
  SMALL_NON_PROFIT_ORGANIZATIONS: "Petites ASBL",
  MEDIUM_AND_LARGE_NON_PROFIT_ORGANIZATIONS: "Moyenne et grandes ASBL",
  NON_RESIDENTS: "Non résidents",
  FOREIGN_COMPANIES: "Entreprises étrangères",
  DOCTOR: "Médecin",
  PROFESSIONS: "Professions libérales",
  PHYSIOTHERAPIST: "Kiné",
  PHARMACIST: "Pharmacien",
  DENTISTS: "Dentistes",
  CULTURE: "Culture",
  ARTISTS: "Artistes",
  PERFORMING_ARTS: "Arts de la scène",
  YOUTH_ASSISTANCE: "Aide à la jeunesse",
  National: "National",
  Regional: "Régional",
  Local: "Local",
  ACCOUNTING_AUDITING: "Expertise comptable & Audit",
  TAX_ADVICE: "Conseil fiscal",
  SOCIAL_COUNCIL: "Conseil social",
  LEGAL_ADVICE: "Conseil juridiques",
  ACCOMPAGNEMENT: "Accompagnement",
  CERTIFICATION: "Certification",
  CONSULTING: "Consulting",
  SUPPORTED_ACCOUNTING_SOFTWARE: "Logiciels comptables supportés",
  DIGITAL_PLATFORMS: "Plateformes digitales",
  TYPE_OF_PROFESSIONAL: "Type de professionnel",
  SECTOR: "Secteur",
  specialty: "spécialité",
  manage_fiduciary: "Gérer fiduciaire",
  manage_specialties: "Gérer les spécialités",
  manage_apps: "Gérer les apps",
  contact_messages: "Messages de contact",
  fiduciary_contact_messages: "Messages de contact fiduciaire",
  nothing_found: "Rien n'a été trouvé ...",
  sent_at: "Envoyé à",
  name: "Nom",
  select_message_please: "Sélectionnez un message, s'il vous plaît",
  manage_fiduciary_specialties: "Gérer les spécialités fiduciaires",
  text_delete_item: "Êtes-vous sûr de vouloir supprimer cet élément ?",
  add_specialty: "Ajouter une spécialité",
  skills: "Compétences",
  subject: "Sujet",
  message: "Message",
  send_message: "Envoyer le message",
  add_specialty_bloc: "Ajouter un bloc de spécialité",
  title_required: "Titre obligatoire",
  icon_required: "Icon obligatoire",
  create_specialty_type: "Créer un type de spécialités",
  create_specialty: "Créer une spécialité",
  create_specialty_bloc: "Créer un bloc de spécialités",
  name_fr: "Nom Fr",
  name_en: "Nom En",
  name_nl: "Nom Nl",
  has_choices: "Choix unique",
  isActivated: "Activé",
  multiselect: "Sélection multiple",
  number_favorites_allowed: "Nombre de favoris autorisés",
  search_placeholder: "Recherche...",
  reply: "Repondre",
  at: "à",
  successfully_sent: "Envoyé avec succès",
  rates_list: "Liste des prix",
  manage_rates: "Gérer les prix",
  rate_type: "Type de prix",
  number_from: "Nombre de",
  rate_monthly: "Prix par mois",
  rate_annualy: "Prix par an",
  text_delete_rate: "Êtes-vous sûr de bien vouloir supprimer ce tarif ?",
  per_month: "par mois",
  per_year: "par année",
  add_new_rate: "Ajouter un nouveau tarif",
  enabled: "Activé",
  disabled: "Désactivé",
  update: "Modifier",
  email_link_expired: "Désolé, ce lien a expiré. Veuillez vous connecter!",
  office_number: "Numéro du cabinet",
  office_name: "Nom du cabinet",
  uen_as: "En tant que cabinet",
  number_collaborator: "Nombre de collaborateurs",
  membership_includes: "Votre adhésion comprend",
  validate_membership: "Valider mon adhésion",
  portals: "portails",
  public_portal: "Portail Public",
  public_portal_txt:
    "Une fenêtre sur le monde extérieur en un seul clic. Ce portail vous permet de disposer d’une présence internet publique sans effort. Après une mini-configuration, votre fiduciaire existe dans un look & feel « United Associates » et les clients qui vous recherchent pourront retrouver des informations de base sur votre bureau : localisation, actualités, équipe, heures d’ouverture… L’accès est public, aucune authentification n’est nécessaire.",
  collaborator_portal: "Portail Collaborateur",
  collaborator_portal_txt:
    "Ce portail est toujours activé. Il vous permet de fonctionner dans un environnement ou chacun de vos collaborateurs accède et partage les différentes ressources de UA. L’accès est possible pour tous collaborateurs qui sont logués.",
  intalled_apps: "applications installées",
  blog_app_txt:
    "Utiliser l’application Blog pour accéder gratuitement à l’actualité économique, juridique, comptable et fiscale. Utilisez ces contenus pour votre organisation.",
  blog_app_txt_1:
    "Une actualité continue en matière fiscale, comptable, sociale et économiques",
  blog_app_txt_2: "Une newsletter mensuelle préparée, prête à l'emploi",
  offfcourse_app_txt:
    "Profitez avec vos collaborateurs d'un accès Live & Replay à toute la bibliothèque <a target=\"_blank\" href=\"https://offfcourse.be\">OFFFCOURSE.be</a>",
  offfcourse_app_txt2:
    "Pour rappel, oFFFcourse est un catalogue de formation en matière fiscale, comptable et de droit en continuel production (2 à 6 production par semaine en français et néerlandais).",
  welcome_title: "Bienvenue sur",
  home_title:
    "Le réseau technologique du savoir et de la connaissance partagée",
  free_test_period: "30 jours d'essai gratuit",
  start_now: "Commencer maintenant",
  subject_to_vat: "Soumis à la TVA",
  apps: "Applications",
  installed: "Installée",
  app_offfcourse_details_1_title: "Abonnement Premium inclus",
  app_offfcourse_details_1_detail1:
    "Un accès gratuit et partagé pour 4 collaborateurs, à l'achat d'un premium (sans attestation ITAA) oFFFcourse",
  app_offfcourse_details_1_detail2:
    "Une application de diffusion et de mobilité pour regarder aisément les formation en cabinet",
  manage_premium_sharing: "Gérer le partage premium",
  collaborator_count_reached:
    "Vous pouvez ajouter jusqu'à %count% collaborateur(s)",
  email_link_size_reached:
    "Désolé! Veuillez contacter le cabinet %name%, pour augmenter la taille des collaborateurs",
  upgrade_successfully: "Votre nouveau abonnement a bien été pris en compte",
  country: "Pays",
  already_member: "Déjà membre?",
  complete_personal_infos: "Compléter mon compte personnel",
  default_domain: "Domaine par défaut",
  custom_subdomain: "Sous-domaine personnalisé",
  create_group: "Créer un groupe",
  Day: "Jour",
  Morning: "Matin",
  Afternoon: "Après-midi",
  MON: "LUN",
  TUE: "MAR",
  WED: "MER",
  TUR: "JEU",
  FRI: "VEN",
  SAT: "SAM",
  SUN: "DIM",
  Apply_for_all_days: "Appliquer pour tous les jours",
  "No work": "Pas de travail",
  select_category: "Choisir une catégorie",
  select_type: "Sélectionner le type",
  no_options: "Aucune option",
  Specialties: "Spécialités",
  specialties_subtitle:
    "Spécifiez vos compétences, technologies, type de clientèle et zone géographique. Evitez de tout cocher…",
  title_fr: "Titre Français",
  title_en: "Titre Anglais",
  title_nl: "Titre Néerlandais",
  description_fr: "Déscription Française",
  description_en: "Déscription Anglaise",
  description_nl: "Déscription néerlandaise",
  article_feed_tooltip: "Sélectionnez l’origine des articles à afficher",
  scope_tooltip:
    "Chaque article dispose d’une information “scope”. Filtrer les article à afficher selon le scope",
  relevance_tooltip:
    "N’afficher que les articles avec une pertinence d’au moins…",
  server_error: "Serveur inaccessible - Re-essayer plus tard",
  invalid_credentials: "Identifiants invalides",
  check_email_help_txt:
    "Pour toute question ou suggestion, ou si vous rencontrez un problème de connexion, l’équipe United Associates est à votre entière disposition via l’adresse info@unitedassociates.be ou par téléphone au 0473/229.313.",
  monthly_subscription: "Abonnement mensuel",
  yearly_subscription: "Abonnement annuel",
  valid_until: "valide jusqu'au",
  error_try_again: "Une erreur est survenue. Veuillez réessayer",
  session_expired: "Session expirée. Veuillez vous connecter",
  manage_account: "Gérer mon abonnement",
  public_domain: "Domaine public",
  public_domain_rules:
    "Le sous-domaine doit être unique et ne doit pas contenir d'espaces ou de caractères spéciaux",
  suggestion: "Suggestion:",
  invalid_subdomain:
    "Sous-domaine non valide, le sous-domaine ne doit pas contenir d'espaces ou de caractères spéciaux. Veuillez vérifier le sous-domaine suggéré ci-dessous",
  subdomain_already_exists: "Le sous-domaine existe déjà",
  newsletter_preferences_title: "Séléctionnez vos préférences Newsletters",
  plans_newsletter_title: "L’APP’ NEWSLETTERS - (In)Formez vos clients",
  articles_option_title: "Flux d'articles",
  articles_option_1: "Actualités FFF",
  articles_option_2: "Publications de mon cabinet",
  articles_option_3: "Fréquence d'envoi",
  customization_option_title: "Customization",
  customization_option_1: "Logo de mon cabinet",
  customization_option_2: "Thème modèle",
  basic: "Basic",
  plans_newsletter_1_max_articles_fff: "1 articles",
  plans_newsletter_1_max_my_articles: "",
  light_mode: "Mode clair",
  edito: "Édito",
  plans_newsletter_2_max_articles_fff: "Jusqu'à 6 articles",
  plans_newsletter_2_max_my_articles: "Maximum 2 articles",
  personalized: "Personnalisée",
  plans_newsletter_3_max_articles_fff: "Jusqu'à 8 articles",
  plans_newsletter_3_max_my_articles: "Jusqu'à 8 articles",
  light_dark_mode: "Mode clair ou sombre",
  month: "Mois",
  predefined: "prédéfinie",
  by_choice: "au choix",
  by_week: "Hebdomadaire",
  by_two_weeks: "Bimensuelle",
  by_month: "Mensuelle",
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
  define_sender: "Définir l’expéditeur",
  program_nl_title: "Programmation de votre envoi (Plan ",
  manage_sending_emails:
    "Vous devez choisir ici avec quel expéditeur vous allez envoyer votre newsletter",
  first_two_weeks: "les premiers deux semaines",
  second_two_weeks: "les deuxièmes deux semaines",
  first_week: "Semaine 1",
  second_week: "Semaine 2",
  third_week: "Semaine 3",
  fourth_week: "Semaine 4",
  manage_newsletter_campaign: "Gérer la campagne newsletter",
  plans_newsletter_desc:
    "Via une application simple et efficace, développez une relation éclairée avec vos clients grâce à un outil de newsletters automatisé et personnalisable. En quelques clics, rédigez des newsletters de haute qualité et mettez au fait de l’actualité vos clients les plus avisés. Avec une mise en place facile et rapide, un système de validation efficace, et un envoi régulier automatique, votre entreprise sera mis en lumière de manière pro-actif auprès de vos clients. Exploitez le meilleur de l’information fiscale, montrez votre savoir et partagez-le avec vos clients.",
  frequency_desc:
    "Hebdomadaire, bimensuelle ou mensuelle : sélectionnez la fréquence d’envoi automatique de vos newsletters.",
  fff_news_desc:
    "En fonction de l’option choisie, vous pouvez envoyer jusqu’à 8 articles pertinents, de façon automatique ou personnalisée, auprès de votre public cible. Chaque newsletter devra bien entendu être validé par le rédacteur en chef (gestionnaire des newsletter de la fiduciaire)",
  my_pub_desc:
    "Créez et partagez vos propres newsletters auprès de vos collaborateurs et clients.",
  pub_logo_desc:
    "Affichez votre logo sur chacune de vos newsletters pour mettre en avant votre expertise auprès de vos clients et collaborateurs.",
  theme_desc:
    "Personnalisé les couleurs de votre newsletters et rendez l’expérience lecteur au plus proche de votre entreprise ?",
  unique_mode: "Mode unique",
  customized_mode: "Mode personnalisable",
  week: "Semaine",
  week_desc_1: "Semaine 1 et 3",
  week_desc_2: "Semaine 2 et 4",
  newsletter_app_txt:
    "Tenez directement informés vos clients sur l’actualité fiscale la plus pertinente en bénéficiant d’une bibliothèque d’articles de haute valeur-ajoutée. En un simple clic, générez une Newsletter à l’image de votre fiduciaire alimentée par les meilleurs experts du secteur.",
  app_newsletter_details_1_title: "2 formules disponibles",
  app_newsletter_details_1_detail1:
    "Basic : Une newsletter de 8 articles automatiquement composées que vous décidez d’envoyer à votre rythme sur un simple clic",
  app_newsletter_details_1_detail2:
    "Edito : Créez votre propre édito et sélectionnez les articles les plus pertinents à votre clientèle ",
  app_newsletter_details_1_detail3:
    "Full personnalisé : Bénéficiez d’un outil de gestion de newsletters totalement personnalisable et diffusée à votre rythme",
  client_portal: "Portail client",
  configuration: "Configuration",
  portal_collaborator_title: "Bienvenue sur le portail Collaborateur",
  portal_collaborator_txt:
    "Votre portail collaborateur n’est pas actif. Demandez à votre manager d’activer le portail collaborateur",
  program_nl_desc:
    "Définissez ici les critère d’envoi en fonction de votre pack",
  choose_date: "Date d’expédition de votre envoi",
  choose_date_desc: "Définissez ici la date d’envoi de votre newsletter",
  performance_of: "Performances de",
  hour_abr: "h",
  hours: "heures",
  minutes: "minutes",
  over: "sur",
  no_data_found: "Aucune donnée trouvée",
  lite: "Lite",
  recipient: "destinataire",
  senders: "expéditeur",
  wrong_file_format: "Format de fichier incorrect (doit être une image)",
  file_upload_error: "Erreur de téléchargement de fichier",
  description_without_title: "Vous avez ajouté une description sans titre",
  add_at_least_one_title: "Titre obligatoire",
  add_image: "Image obligatoire",
  edit_service: "Modifier le service",
  create_service: "Créer un service",
  image_import_text: "Faites glisser une image ici ou Importez un fichier",
  classic_design: "Design classique",
  customizable_design: "Design personnalisable",
  testimonials: "Témoignages",
  add_testimonial: "Ajouter un témoignage",
  add_faq: "Ajouter une FAQ",
  answer_without_question: "Vous avez ajouté une réponse sans question",
  question_without_answer: "Vous avez ajouté une question sans réponse",
  question_required: "Question obligatoire",
  edit_faq: "Modifier la FAQ",
  create_faq: "Créer une FAQ",
  question_fr: "Question française",
  question_en: "Question anglaise",
  question_nl: "Question néerlandaise",
  answer_fr: "Réponse française",
  answer_en: "Réponse en anglais",
  answer_nl: "Réponse néerlandaise",
  testimonial_required: "Témoignage obligatoire",
  author_required: "Auteur obligatoire",
  edit_testimonial: "Modifier le témoignage",
  create_testimonial: "Créer un témoignage",
  testimonial_fr: "Témoignage français",
  testimonial_en: "Témoignage en anglais",
  testimonial_nl: "Témoignage néerlandais",
  author: "Auteur",
  headline: "Titre",
  invitation_successfully: "Invitation acceptée. Veuillez vous connecter!",
  invitation_pwd_successfully:
    "Invitation acceptée. Veuillez définir un mot de passe!",
  invitation_reject: "Invitation rejetée.",
  collaborator_exist: "Ce collaborateur existe dans votre organisation",
  university: "Université",
  role: "Rôle",
  students: "Etudiants",
  student: "Etudiant",
  students_list: "Liste des étudiants",
  manual_collab_title: "Ajout Manuel d’un collaborateur",
  manual_collab_subtitle: "Remplissez les champs pour ajouter un collaborateur",
  manual_student_title: "Ajout Manuel d’un étudiant",
  manual_student_subtitle: "Remplissez les champs pour ajouter un étudiant",
  recipients_desc:
    "Choisissez le nombre de collaborateurs et vos clients qui bénéficieront de vos newsletters.",
  senders_desc:
    "Choisissez le nombre de collaborateurs ayant la possibilités d'envoyer vos newsletters.",
  users_imported_with_errors:
    "Certains enregistrements ont bien été enregistré, d'autres ont rencontré une erreur. Veuillez rééssayer",
  import_students: "Importer les étudiants",
  show_more: "Voir plus",
  invitation_confirm: "Confirmation",
  invitation_confirm_text:
    "Êtes-vous sûr(e) de vouloir envoyer une invitation pour acheter un premium ?",
  invitation_sent: "Invitation envoyée avec succès",
  share_all_confirm_text:
    "Êtes-vous sûr(e) de vouloir partager le premium avec tous les collaborateurs ?",
  details_pack_lite:
    "Lite: Envoyez une newsletter par mois à vos clients. Pack limité à 25 clients.",
  details_pack_perso:
    "Full personnalisé: Offrez à vos collaborateurs la possibilité de garder un contact rapproché avec leurs clients. Envoyez une newsletter composée de 8 articles toutes les semaines. Jusqu'à 10.000 envois par semaine.",
  themes_pages: "Themes & Pages",
  theme_title: "Liste des thèmes et pages",
  theme_sub_title: "Ajouter ou configurer les thèmes et pages",
  theme_search_placeholder: "Rechercher les thèmes",
  page_search_placeholder: "Rechercher les pages",
  pages: "pages",
  dropzone_text: "Cliquez ou glisser et déposer votre image ici",
  invalid_page_title: "Titre de la page non valide",
  invalid_private_groups: "Vous devez spécifier des groupes pour les abonnés",
  errors: "Erreurs",
  subscribers_groups: "Groupes d'abonnés",
  invalid_domain_title: "Titre du domain non valide",
  invalid_theme_title: "Titre du thème non valide",
  invalid_theme_domain: "Selectionnez les domaines du thème",
  add_domain: "Ajouter un domaine",
  edit_domain: "Modifier le domaine",
  edit_theme: "Modifier le thème",
  add_theme: "Ajouter un thème",
  theme_isdefault: "Theme par défaut",
  is_editable: "Est Éditable",
  used: "Utilisé",
  unused: "Non utilisé",
  modal_delete_title: "Confirmation de suppression",
  modal_delete_text: "Êtes-vous sûr de bien vouloir supprimer cet élément ?",
  add_page: "Ajouter une page",
  linked_pages: "Pages liées",
  domains: "domaines",
  domain_title: "Liste des domaines",
  domain_sub_title: "Ajouter ou configurer les domaines",
  packs_list: "Liste des packs",
  manage_packs: "Gérer les packs",
  add_new_pack: "Ajouter un nouveau pack",
  save_and_continue: "Enregistrer et continuer",
  formula: "Formule",
  add_app: "Ajouter une application",
  has_slider: "Possède un slider?",
  slider_min: "Valeur minimale",
  slider_max: "Valeur maximale",
  slider_step: "Valeur d'incrémentation",
  slider_label: "Titre du slider",
  slider_text: "Description du slider",
  delegate: "Déléguer",
  delegations: "Délégations",
  title_delegation: "Gestion des délégations",
  title_delegation_type: "Types de délégation",
  delegation_type: "Type délégation",
  for_count_clients: "Pour %count% clients",
  title: "Titre",
  REQUIRED_FIELD: "Ce champ est obligatoire.",
  TOO_SHORT: "Ce champ doit contenir au moins 2 caractères.",
  TOO_LONG: "Ce champ ne peut pas contenir plus de 10 caractères.",
  checking_contact_existence: "(Vérification de l'existence d'un contact...)",
  add_to_contacts: "Ajouter aux contacts",
  text_add_contact:
    "Voulez vous ajouter cette personne a la liste des contactes ?",
  source: "Source",
  FFF_SITE: "Site Forum for the future",
  SITE_ART_CONSULT: "Site Art Consultant",
  SITE_COMPTA_MEDECIN: "Site Comptabilité Médecin",
  SITE_COMPTA_CONSULTANT: "Site Comptabilité Consultant",
  SITE_COMPTA_VETERINAIRE: "Site Comptabilité Vétérinaire",
  SITE_COMPTA_AVOCAT: "Site Comptabilité Avocat",
  SITE_DEG: "Site DEG & PARTNERS",
  SITE_COMPTA_DENTISTE: "Site Comptabilité Dentiste",
  order_member_design: "Design membre de l’ordre",
  about: "À propos",
  manage_contacts: "Gérer les contacts",
  contact_added_successfully: "Contact ajouté avec succès",
  contact_already_exist: "Ce contact existe déjà",
  create_media: "Ajouter Media",
  edit_media: "Modifier Media",
  partners: "Partenaires",
  add_partner: "Ajouter Partenaire",
  uploading: "Téléchargement...",
  add_new_number: "Ajouter un nouveau numéro",
  in_a_few_numbers: "En quelques chiffres",
  standard: "Standard",
  articles: "articles",
  paid: "Payé",
  $pageName$_page_content: "Contenue de la page $pageName$",
  banner: "Bannière",
  history: "Histoire",
  values: "Valeurs",
  add_new_value: "Ajouter une nouvelle valeur",
  add_video: "Ajouter une video",
  remove_video: "Supprimer la vidéo",
  gallery: "Galerie",
  add_section: "Ajouter une section ",
  packs: "Packs",
  packs_management: "Gestion des packs",
  add_pack: "Ajouter un pack",
  monthly_price: "Prix mensuel",
  yearly_price: "Prix annuel",
  pack_features: "Fonctionnalités du pack",
  available: "Disponible",
  value: "Valeur",
  features_management: "Gestion des fonctionnalités",
  add_feature_category: "Ajouter une catégorie",
  add_feature: "Ajouter une fonctionnalité",
  remove_feature: "Supprimer fonctionnalité",
  remove_feature_category: "Supprimer catégorie",
  features_list: "Liste des fonctionnalités",
  category_details: "Détails de la catégorie",
  remove_pack: "Supprimer pack",
  pack_details: "Détails du pack",
  member_of: "membre de :",
  order_of_experts: "l'Ordre des Experts-Comptables et Conseils Fiscaux",
  MON_full: "Lundi",
  TUE_full: "Mardi",
  WED_full: "Mercredi",
  TUR_full: "Jeudi",
  FRI_full: "Vendredi",
  SAT_full: "Samedi",
  SUN_full: "Dimanche",
  new_sender: "Nouveau expéditeur",
  full_name: "Nom Prénom",
  add_to_my_list: "Ajouter à ma liste",
  manage_membership: "Liste des adhésions",
  memberships: "Adhésions",
  upload_max_file_size:
    "La taille du fichier dépasse la limite de %maxFileSize%.",
  Mark_as_sender: "Marquer comme expéditeur",
  not_subject_to_vat: "Non soumis à la TVA",
  organization: "Organisation",
  year: "Année",
  request_sent: "Demande envoyée",
  request_sent_successfully: "Demande envoyée avec succès",
  customization_request: "Demande de personnalisation",
  customization_request_confirm_title:
    "Demande de personnalisation du nom de domaine",
  customization_request_confirm_text:
    "Voulez-vous faire une demande de personnalisation ?",
  or: "Ou",
  save_priority: "Sauvegarder le nouvel ordre",
  logged_as: "Se connecter en tant que",
  deleted: "Supprimé",
  add_note: "Ajouter une note",
  theme_ismanual: "Est un manuel",
  unsubscribe: "Se désabonner",
  confirm_unsubscribe_msg:
    "Veuillez confirmer que vous souhaitez vous désabonner de votre adresse e-mail",
  from_future_mails_from: "des futurs e-mails de",
  unsubscribed_msg: "Vous avez été désinscrit !",
  warning_unsubscribe_msg: "Vous ne recevrez plus d'e-mails de",
  unsubscribed_by_accident: ", désabonné par accident ?",
  subscribed_msg: "s'abonner",
  unsubscribe_from_ua: "Se désabonner de United Associate",
  choose_plan: "Choisissez le plan de la newsletter",
  check_your_phone:
    "Veuillez vérifier votre téléphone pour valider votre numéro",
  confirm_logout_title: "Connexion requise",
  confirm_logout_text: "Pour accéder à votre profil, vous devez vous connecter. Voulez-vous continuer ?",
  connection: "S'identifier",
};
export default faqFr;
