import { useSelector } from "react-redux";

import DrawerIframe from "../common/DrawerIframe";
import {
  TTP_HELP_URL,
  TTP_ACCOUNTING_URL,
  TTP_BLOG_URL,
  TTP_POWERTEAM_URL,
  TTP_WEBTOOL_URL,
  TTP_OFFFCOURSE_URL,
} from "../../config";
import { useEffect, useState } from "react";
import { getOrganizationSettings } from "../../api";
import ChatBot from "../ChatBot";

const Drawer = ({ activeApp, hasPremiumWatch, setActiveApp }) => {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const currentPortal = useSelector((state) => state.params.currentPortal);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const params = [];
  const [portals, setPortals] = useState(null);
  const [teamCurrentUrl, setTeamCurrentUrl] = useState("");

  useEffect(() => {
    const goTo = new URLSearchParams(window.location.search).get("GOTO");
    if (goTo) {
      localStorage.setItem("teamCurrentUrl", goTo);
    }
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (
        event.data &&
        typeof event.data === "object" &&
        event.data.type === "PATHNAME_CHANGE"
      ) {
        const pathname = event.data.pathname;
        if (teamCurrentUrl !== pathname) {
          try {
            localStorage.setItem("teamCurrentUrl", pathname);
          } catch (error) {
            console.error("Error accessing localStorage:", error);
          }
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const savedUrl = localStorage.getItem("teamCurrentUrl");
    if (savedUrl) {
      setTeamCurrentUrl(savedUrl);
    }
  }, []);

  useEffect(() => {
    if (fiduciary) {
      getOrganizationSettings(auth.token, fiduciary.id)
        .then((response) => {
          if (
            response.data.data &&
            response.data.data.hubPreferences &&
            response.data.data.hubPreferences.portals
          ) {
            setPortals(response.data.data.hubPreferences.portals);
          }
        })
        .catch((e) => {});
    }
  }, [fiduciary, auth]);

  useEffect(() => {
    const event = new CustomEvent("activeApp", { detail: activeApp });
    window.dispatchEvent(event);
  }, [activeApp]);

  let blog = null;
  if (portals) {
    const filters = {
      csScope: null,
      categories: null,
      relevance: null,
      organization: null,
      rss: null,
      authorTypes: null,
    };

    if (currentPortal === "COLLABORATOR") {
      blog = portals.collaborator.config.apps.filter(
        (el) => el.id === "BLOG"
      )[0];
    } else if (currentPortal === "CLIENT") {
      blog = portals.client.config.apps.filter((el) => el.id === "BLOG")[0];
    }

    const scope = [];

    if (blog && blog.scope) {
      if (blog.scope.includes("COLLABORATOR")) {
        if (blog.type) {
          if (blog.type === "MINE") {
            scope.push("ALL_COLLABORATORS");
          } else if (blog.type === "BOTH") {
            scope.push("ALL_COLLABORATORS");
            scope.push("FID_COLLABORATOR");
          } else {
            scope.push("FID_COLLABORATOR");
          }
        }
      }
      if (blog.scope.includes("CLIENT")) {
        if (blog.type) {
          if (blog.type === "MINE") {
            scope.push("ALL_CLIENTS");
          } else if (blog.type === "BOTH") {
            scope.push("ALL_CLIENTS");
            scope.push("FID_CLIENT");
          } else {
            scope.push("FID_CLIENT");
          }
        }
      }
      if (blog.scope.includes("PUBLIC")) {
        scope.push("PUBLIC");
      }
    } else {
      scope.push("PUBLIC");
    }

    filters.csScope = scope;
    if (blog) {
      if (
        blog.authorTypes &&
        blog.authorTypes.length > 0 &&
        blog.authorTypes.length < 8
      ) {
        filters.authorTypes = blog.authorTypes;
      }
      if (blog.categories && blog.categories.length > 0) {
        filters.categories = blog.categories;
      }
      if (blog.relevance) {
        filters.relevance = blog.relevance;
      }
      if (blog.type) {
        filters.organization = blog.type === "FFF" ? 9 : fiduciary.id;
        filters.rss =
          blog.type === "FFF" || blog.type === "MINE" ? true : false;

        filters.blog_type = blog.type;
      }
    }

    params.push("filters=" + encodeURIComponent(JSON.stringify(filters)));
  }

  if (activeApp === "BLOG") {
    // if (user.blogRole && user.blogRole.length > 0) {
    //   params.push("role=" + user.blogRole[0].role);
    //   const mandated = user.blogRole[0].mandated ? 1 : 0;
    //   params.push("mandated=" + mandated);
    // }
    let apps = [];
    if (fiduciary) {
      if (blog && blog.source?.length > 0) {
        apps = [...blog.source];
        if (blog.source.includes("MY_LIBRARY")) {
          apps.push("BLOG_CREATE");
        }
      } else {
        apps.push("FFF_LIBRARY");
      }

      let pack = "";
      if (fiduciary?.newsletterPreferences?.apps) {
        const blogApp = fiduciary.newsletterPreferences.apps.filter(
          (a) => a.type === "BLOG_NL_CREATE"
        );
        if (blogApp && blogApp[0]?.config) {
          pack = blogApp[0].config;
        }
      }
      if (!pack) {
        pack = "LITE";
      }
      params.push("pack=" + pack);
    }
    params.push("portal=" + currentPortal);
    params.push("apps=" + encodeURIComponent(apps.join(",")));
    params.push("without_header=1");
    params.push("without_footer=1");
    params.push("fiduciary=" + fiduciary.id);
    params.push("hasPremiumWatch=" + (hasPremiumWatch ? 1 : 0));
    return (
      <DrawerIframe
        src={`${TTP_BLOG_URL}/${lng}`}
        params={params}
        isBlog={true}
        setActiveApp={setActiveApp}
      />
    );
  }
  if (activeApp === "NEWSLETTER") {
    params.push("fiduciary=" + fiduciary.id);
    params.push("without_header=1");
    params.push("lng=" + lng);
    return <DrawerIframe params={params} isNewsletter={true} />;
  }
  if (activeApp === "FAQ") {
    if (
      fiduciary &&
      fiduciary.faqPreferences &&
      fiduciary.faqPreferences.source &&
      fiduciary.faqPreferences.source.length > 0
    ) {
      params.push(
        "source=" +
          encodeURIComponent(fiduciary.faqPreferences.source.join(","))
      );
    }
    params.push("organization_id=" + fiduciary.id);
    params.push("without_header=1");
    let faqUrl = TTP_HELP_URL + "/" + lng;
    if (fiduciary.id == 4) {
      faqUrl += "/forum";
    }
    // if (currentPortal === "CLIENT") {
    //   faqUrl += "/search";
    //   params.push("without_submenu=1");
    // }

    return <DrawerIframe src={faqUrl} params={params} />;
  }
  if (activeApp === "DASHBOARD") {
    let accountingUrl = TTP_ACCOUNTING_URL;

    params.push("portal=" + currentPortal);
    params.push("organization_id=" + fiduciary.id);
    params.push("lng=" + lng);
    params.push("without_header=1");
    return <DrawerIframe src={accountingUrl} params={params} />;
  }

  if (activeApp === "POWERTEAM") {
    let powerTeamUrl = TTP_POWERTEAM_URL;
    params.push("without_header=1");
    params.push("lng=" + lng);
    params.push("organization_id=" + fiduciary.id);

    return (
      <DrawerIframe src={`${powerTeamUrl}${teamCurrentUrl}`} params={params} />
    );
  }
  if (activeApp === "WEBTOOLS") {
    let webtoolUrl = TTP_WEBTOOL_URL;
    params.push("lng=" + lng);
    params.push("without_header=1");
    params.push("organization_id=" + fiduciary.id);
    return <DrawerIframe src={webtoolUrl} params={params} />;
  }
  if (activeApp === "OFFFCOURSE") {
    let offfcourse = TTP_OFFFCOURSE_URL + "/watch?lng=" + lng;
    params.push("without_header=1");
    return (
      <DrawerIframe src={offfcourse} params={params} isOFFFCourse={true} />
    );
  }
  if (activeApp === "AI") {
    return <ChatBot user={auth.user} token={auth.token} lng={lng} />;
  }
  return null;
};

export default Drawer;
