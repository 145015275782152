import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import moment from "moment";

import { getMemberships, getUaMemberships } from "../../api";
import _ from "../../i18n";
import { SELECT_STYLES } from "../../config";

import Pagination from "../common/Pagination";
import Loader from "../common/Loader";

import styles from "./ManageMembership.module.scss";

const statusOptions = [
  { label: _("pending"), value: "PENDING" },
  { label: _("paid"), value: "PAID" },
];

const sourceOptions = [
  { label: "UA", value: "UA" },
  { label: "OECCBB", value: "OECCBB" },
];

const currentYear =
  moment().month() == 11 ? moment().year() + 1 : moment().year();

const yearsOptions = [{ value: currentYear, label: currentYear }];

for (let i = currentYear - 1; i >= 2024; i--) {
  yearsOptions.push({ value: i, label: i });
}

const ManageMembership = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [status, setStatus] = useState([statusOptions[1]]);
  const [source, setSource] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [memberships, setMemberShips] = useState(null);
  const [formulas, setFormulas] = useState(null);
  const [formulaFilter, setFormulaFilter] = useState(null);
  const [formulaOptions, setFormulaOptions] = useState([]);
  const [year, setYear] = useState(yearsOptions[0]);
  const [sort, setSort] = useState({
    property: "createdAt",
    dir: "desc",
  });

  const LIMIT = 20;

  let { isLoading, data } = useQuery(
    [
      "listMemberships",
      token,
      year,
      sort,
      status,
      source,
      currentPage,
      formulaFilter,
    ],
    async () => {
      if (token) {
        if (!memberships) {
          try {
            const response = await getUaMemberships(token);
            let membershipTab = {},
              formulasTab = {},
              formulaOptionsTab = [];
            response.data.data.forEach((item) => {
              membershipTab[item.id] = {
                id: item.id,
                name: item.nameFr,
                hasSlider: item.hasSlider,
                type: item.type,
              };
              item.uaMemberShipFormulas.forEach((formula) => {
                formulasTab[formula.id] = {
                  id: formula.id,
                  name: formula.nameFr,
                  annualPrice: formula.annualPrice,
                };
                formulaOptionsTab.push({
                  value: formula.id,
                  label: formula.nameFr,
                });
              });
            });
            setFormulaOptions(formulaOptionsTab);
            setMemberShips(membershipTab);
            setFormulas(formulasTab);
          } catch (e) {}
        }
        try {
          const formulaIds = formulaFilter
            ? formulaFilter.map((i) => i.value)
            : [];
          const tabStatus = status.map((s) => s.value);
          const response = await getMemberships({
            token,
            status: tabStatus,
            page: currentPage,
            limit: LIMIT,
            sort,
            year: year.value,
            formulaIds,
            source: source ? source.value : null,
            addOnboarding: true,
          });
          return response.data;
        } catch (e) {
          return [];
        }
      }
    },
    { cacheTime: 0 }
  );

  const handleStatusChange = (e) => {
    setStatus(e);
  };

  return (
    <div className="grid-container">
      <h1 className={styles.pageTitle}>
        Liste des adhésions OECCBB{" "}
        {!isLoading && data?.nbResult && <span>({data.nbResult})</span>}
      </h1>

      <div
        className={`${styles.filterBar} ${
          props.fromTab && styles.filterColumn
        }`}
      >
        <label>Filtrer par:</label>
        <div className={styles.filter}>
          <label>Année</label>
          <Select
            styles={SELECT_STYLES}
            options={yearsOptions}
            isSearchable={false}
            value={year}
            onChange={(e) => setYear(e)}
          />
        </div>
        <div className={styles.filter}>
          <label>Status</label>
          <Select
            isMulti
            styles={SELECT_STYLES}
            options={statusOptions}
            isSearchable={false}
            value={status}
            onChange={handleStatusChange}
          />
        </div>
        <div className={styles.filter}>
          <label>Source</label>
          <Select
            isClearable
            styles={SELECT_STYLES}
            options={sourceOptions}
            isSearchable={false}
            value={source}
            onChange={(e) => setSource(e)}
          />
        </div>
        <div className={styles.filter}>
          <label>Formule</label>
          <div className={styles.formulaFilter}>
            <Select
              isMulti
              styles={SELECT_STYLES}
              options={formulaOptions}
              isSearchable={false}
              value={formulaFilter}
              onChange={(e) => setFormulaFilter(e)}
            />
          </div>
        </div>
      </div>

      <table className={`${styles.table} unstriped`}>
        <thead>
          <tr>
            <th width="40%" className={styles.nowrap}>
              <span>Formule</span>
            </th>
            <th width="20%">Utilisateur</th>
            <th width="20%">Email</th>
            <th>Téléphone</th>
            <th>Statut</th>
            <th>Date</th>
            <th>UA Onboarding</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={6} className={styles.centerCell}>
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#B2BCC6"}
                />
              </td>
            </tr>
          ) : data?.data && data.data.length > 0 ? (
            data.data.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    {item.data.map((row) => (
                      <p className={styles.btmBorder}>
                        {memberships[row.membersShip].name +
                          " " +
                          formulas[row.formula].name}
                      </p>
                    ))}
                  </td>
                  {/* <td>
                    {item.data.map((row) => (
                      <p className={styles.btmBorder}>
                        {formulas[row.formula].name}
                      </p>
                    ))}
                  </td> */}
                  <td>
                    {item.user.firstName} {item.user.lastName}
                  </td>
                  <td>{item.user.mainEmail}</td>
                  <td>{item.user.mainPhone}</td>
                  <td>{_(item.status.toLowerCase())}</td>
                  <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                  <td>
                    {item.onBoardingStatus == "PENDING"
                      ? "En attente de validation"
                      : item.onBoardingStatus == "VALIDATED"
                      ? "Validé"
                      : "-"}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6} className={styles.centerCell}>
                Liste vide (aucune donnée ne répond aux filtres)
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data?.nbResult && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.nbResult}
          pageSize={LIMIT}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </div>
  );
};

export default ManageMembership;
