import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";

import { saveFolderContactStatus } from "../../api";
import styles from "./Settings.module.scss";

import _ from "../../i18n";

const ContactInfoBar = () => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [showContactModal, setShowContactModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hideBar, setHideBar] = useState(false);

  const handleContact = () => {
    setIsSaving(true);
    saveFolderContactStatus(auth.token, { id: fiduciary.id, status: "REFUSED" })
      .then((resp) => {
        setHideBar(true);
      })
      .catch((e) => {})
      .finally(() => setIsSaving(false));
  };

  if (
    hideBar ||
    !fiduciary.contactedStatus ||
    fiduciary.contactedStatus !== "NOT_CONTACTED"
  ) {
    return null;
  }

  return (
    <>
      <div className={styles.topInfoBar}>
        Bienvenue sur United Associates ! Un de nos experts prendra bientôt
        contact avec vous pour vous guider dans la prise en main de notre
        plateforme.
        <br />
        <div>
          <span onClick={() => setShowContactModal(true)}>
            Je ne souhaite pas être contacté
          </span>
        </div>
      </div>
      <ModalConfirm
        type="publish"
        isOpen={showContactModal}
        onCancel={() => {
          setIsSaving(false);
          setShowContactModal(false);
        }}
        onConfirm={() => handleContact()}
        inProcess={isSaving}
        title={_("confirm")}
        text={
          "Voulez vous vraiment ne pas être contacter pour la prise en main de notre plateforme ?"
        }
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </>
  );
};

export default ContactInfoBar;
